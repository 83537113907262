import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CollegeCA = () => {

  const ca = useRef(document.createElement("p"));

  const handleCollegeCASBE = () => {
    window.location.href="./CollegeCASBE";
  };

  const handleCollegeCBA = () => {
    window.location.href="./CollegeCBA";
  };

  const handleCollegeCTHM = () => {
    window.location.href="./CollegeCTHM";
  };

  const handleCollegeCE = () => {
    window.location.href="./CollegeCE";
  };

  const handleCollegeCISTM = () => {
    window.location.href="./CollegeCISTM";
  };

  const handleCollegeCED = () => {
    window.location.href="./CollegeCED";
  };

  const handleCollegeCHASS = () => {
    window.location.href="./CollegeCHASS";
  };

  const handleCollegeNursing = () => {
    window.location.href="./CollegeNursing";
  };

  const handleCollegePT = () => {
    window.location.href="./CollegePT";
  };

  const handleCollegeScience = () => {
    window.location.href="./CollegeScience";
  };

  const handleCollegeLaw = () => {
    window.location.href="./CollegeLaw";
  };

  const handleCollegeMedicine = () => {
    window.location.href="./CollegeMedicine";
  };

  const handleCollegeBS = () => {
    window.location.href="./CollegeBS";
  };

  const handleCollegeGLaw = () => {
    window.location.href="./CollegeGLaw";
  };

  const handleCollegePublicHealth = () => {
    window.location.href="./CollegePublicHealth";
  };

  const handleCollegeGovernment = () => {
    window.location.href="./CollegeGovernment";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Colleges</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
              <MenuItem onClick={handleCollegeCASBE}>Architecture</MenuItem>
              <MenuItem onClick={handleCollegeCBA}>Business Administration</MenuItem>
              <MenuItem
                  onClick={() =>
                    ca.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Accountancy
                </MenuItem>
                <MenuItem onClick={handleCollegeCTHM}>Tourism & Hotel Management</MenuItem>
                <MenuItem onClick={handleCollegeCED}>Education</MenuItem>
                <MenuItem onClick={handleCollegeCE}>Engineering</MenuItem>
                <MenuItem onClick={handleCollegeCISTM}>Information System</MenuItem>
                <MenuItem onClick={handleCollegeCHASS}>Humanities, Arts and Social Science</MenuItem>
                <MenuItem onClick={handleCollegeNursing}>Nursing</MenuItem>
                <MenuItem onClick={handleCollegePT}>Physical Therapy</MenuItem>
                <MenuItem onClick={handleCollegeScience}>Science</MenuItem>
                <MenuItem onClick={handleCollegeLaw}>Law</MenuItem>
                <MenuItem onClick={handleCollegeMedicine}>Medicine</MenuItem>
                <MenuItem onClick={handleCollegeBS}>Business School</MenuItem>
                <MenuItem onClick={handleCollegeGLaw}>Graduate School of Law</MenuItem>
                <MenuItem onClick={handleCollegePublicHealth}>Public Health</MenuItem>
                <MenuItem onClick={handleCollegeGovernment}>Government</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '5px', display: { xs: 'none', md: 'flex' } }} />
      <Paper elevation={2} sx={{padding: '16px'}}>
          
      <Box sx={{ flexGrow: 0, justifyContent: "left", backgroundColor: "#A31920"}}>
        <Typography variant="h5" color="white">College of Accountancy</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The College of Accountancy at Pamantasan ng Lungsod ng Maynila is dedicated to becoming a leader in accountancy education through its commitment to academic excellence, innovation, and ethical leadership. With a rigorous curriculum, impactful research, strong industry partnerships, and a culture of integrity and social responsibility, the college aims to produce competent and socially responsible accountants who will contribute significantly to national development. The continuous professional growth of faculty and staff ensures that the college remains at the cutting edge of educational and professional practices, living up to its motto: "Excellence. Integrity. Innovation."
        </Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">History</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        </Typography>
      </Box>      
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Vision</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        "To be a leading institution in accountancy education, recognized for academic excellence, innovation, and ethical leadership, committed to producing competent and socially responsible accountants who will contribute significantly to national development."
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Mission</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        "The College of Accountancy at Pamantasan ng Lungsod ng Maynila is dedicated to providing high-quality education that equips students with the knowledge, skills, and ethical grounding necessary for professional success. We aim to foster a learning environment that promotes critical thinking, innovation, and lifelong learning. 
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Objectives</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Guided by the college's vision and mission, we commit ourselves:
        <br/>1. Deliver a rigorous and relevant curriculum that meets the highest academic and professional standards.
        <br/>2. Engage in impactful research and extension services that address contemporary issues in the field of accountancy.
        <br/>3. Forge strong partnerships with industry, government, and professional organizations to enhance educational and career opportunities for our students.
        <br/>4. Cultivate a culture of integrity, accountability, and social responsibility among our students, faculty, and staff.
        <br/>5. Support the continuous development of our faculty and staff to ensure they remain at the forefront of educational and professional practices."
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Public Offering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The Bachelor of Science in Accountancy (BSA) at Pamantasan ng Lungsod ng Maynila (PLM) is a comprehensive and rigorous academic program designed to develop highly competent accounting professionals. The curriculum integrates theoretical knowledge with practical application to ensure that graduates are well-prepared to meet the demands of the accounting profession.
        <br/>Career Opportunities
        <br/>Graduates of the BSA program have a wide range of career opportunities in various sectors, including public practice, commerce and industry, government, and education. Possible careers and professions include:
        <br/>•        Certified Public Accountant (CPA): Licensed professionals providing audit, tax, and advisory services.
        <br/>•        Financial Accountant: Responsible for preparing financial statements and ensuring accuracy in financial reporting.
        <br/>•        Management Accountant: Focuses on internal financial analysis, budgeting, and cost management to support business decisions.
        <br/>•        Auditor: Conducts audits to evaluate the accuracy and integrity of financial records.
        <br/>•        Tax Consultant: Provides advice on tax planning, compliance, and strategy to individuals and businesses.
        <br/>•        Internal Auditor: Assesses the effectiveness of internal controls and risk management processes within an organization.
        <br/>•        Forensic Accountant: Investigates financial discrepancies and fraud, often working with legal authorities.
        <br/>•        Financial Analyst: Analyzes financial data to assist in investment decisions and financial planning.
        <br/>•        Accounting Information Systems Specialist: Manages and implements accounting software and information systems.
        <br/>•        Budget Analyst: Develops and manages budgets for organizations, ensuring efficient allocation of resources.
        <br/>•        Academician: Engages in teaching, research, and academic administration in educational institutions.
        <br/>•        Consultant: Provides expert advice in accounting, finance, and business strategy to various clients.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Awards and Recognition</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
          plm - 42.06% passing rate
          <br/>national passing rate - 30.28%
      </Typography>
    </Box>

      <br/>
      <br/>
      <br/>
      </Paper>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CollegeCA;
