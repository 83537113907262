import { AppBar, Button, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import presstaff1 from './img/administration/Atty. Relson P. Moral.png';
import presstaff2 from './img/administration/Mr. Ryan Jay SM. Cruz.png';
import presstaff3 from './img/administration/Ms. Lainna P. Ko.png';
import { useRef } from 'react';
import EmailIcon from '@mui/icons-material/EmailRounded';

const SupportStaff = () => {
  const board = useRef(document.createElement("p"));
  const staff = useRef(document.createElement("p"));
  const handleBoard = () => {
    window.location.href="./Administration";
  };

  const handlePresident = () => {
    window.location.href="./President";
  };

  const handleVPresident = () => {
    window.location.href="./VPresident";
  };

  const handleDirectorChief = () => {
    window.location.href="./DirectorChief";
  };

  const handleDean = () => {
    window.location.href="./Dean";
  };

  const handleOrgChart = () => {
    window.location.href="./OrgChart";
  };

  const handlePastPresident = () => {
    window.location.href="./PastPresident";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Presidential Support Staff</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "2vw"}}>
      <AppBar position="relative"style={{ background: 'white', height: "5vw", marginTop: "1vw"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem onClick={handleBoard}>Board of Regents</MenuItem>
        <MenuItem onClick={handlePresident}>The President</MenuItem>
        <MenuItem onClick={handleVPresident}>Management Committee</MenuItem>
        <MenuItem onClick={handleDirectorChief}>Directors and Chiefs</MenuItem>
        <MenuItem onClick={handleDean}>Deans</MenuItem>
        <MenuItem onClick={handleOrgChart}>Organizational Chart</MenuItem>
        <MenuItem
          onClick={() =>
            board.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          Presidential Support Staff
        </MenuItem>
        <MenuItem onClick={handlePastPresident}>Past University Presidents</MenuItem>
      </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '2vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="30%" style={{  marginLeft: "1vw"}}><img src={presstaff1} width="100%" alt="" /></Box>
        <Box width="30%" style={{  marginLeft: "1vw"}}><img src={presstaff2} width="100%" alt="" /></Box>
        <Box width="30%" style={{  marginLeft: "1vw"}}><img src={presstaff3} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="30%" style={{  paddingLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Atty. Relson P. Moral</Typography></Box>
        <Box width="30%" style={{  paddingLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Mr. Ryan Jay SM. Cruz</Typography></Box>
        <Box width="30%" style={{  paddingLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Ms. Lainna P. Ko</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="30%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">rpmoral@plm.edu.ph</Typography></Button></Box>
        <Box width="30%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">rjsmcruz@plm.edu.ph</Typography></Button></Box>
        <Box width="30%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">lpangyarihanko@plm.edu.ph</Typography></Button></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="30%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Chief Executive Staff</Typography></Box>
        <Box width="30%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Head, Executive Assistant </Typography></Box>
        <Box width="30%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Executive Assistant</Typography></Box>
      </Box>

      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default SupportStaff;
