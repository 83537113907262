import { AppBar, Button, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import dean1 from './img/administration/Ms. Luzviminda B. Landicho, MBA, CPA.png';
import dean2 from './img/administration/Arch. Jared Aaron R. Cruz.png';
import dean3 from './img/administration/Engr. Bernard R. Letrero.png';
import dean4 from './img/administration/Dr. Jimmy M. Romero.png';
import dean5 from './img/administration/Engr. Evangeline P. Lubao.png';
import dean6 from './img/administration/Prof. Luningning P. Galindez.png';
import dean7 from './img/administration/Dr. Khatalyn E. Mata.png';
import dean8 from './img/administration/DEAN COLLEGE OF LAW. ATTY. MAGELIO ARBOLADURA.png';
import dean9 from './img/administration/DR. ROSE ANNA BANAL- DEAN-COLLEGE OF MEDICINE.png';
import dean10 from './img/administration/Dr. David Paul R. Ramos.png';
import dean11 from './img/administration/DR. NIL EDWARD F. PANUELOS-DEAN COLLEGE OF PHYSICAL THERAPHY.png';
import dean12 from './img/administration/Prof. Noemi C. Gocuyo.png';
import dean13 from './img/administration/Dr. Aileen I. Atienza.png';

import dean14 from './img/administration/Jerald Lancer O. Abril, CTHM.png';
import dean15 from './img/administration/Joseph Berlin P. Juanzon, Ph.D.png';
import dean16 from './img/administration/Justice Hector L. Hofileña.png';
import EmailIcon from '@mui/icons-material/EmailRounded';

import { useRef } from 'react';

const Dean = () => {
  const dean = useRef(document.createElement("p"));
  const handleBoard = () => {
    window.location.href="./Administration";
  };

  const handlePresident = () => {
    window.location.href="./President";
  };

  const handleVPresident = () => {
    window.location.href="./VPresident";
  };

  const handleDirectorChief = () => {
    window.location.href="./DirectorChief";
  };

  const handleOrgChart = () => {
    window.location.href="./OrgChart";
  };

  const handleSupportStaff = () => {
    window.location.href="./SupportStaff";
  };

  const handlePastPresident = () => {
    window.location.href="./PastPresident";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Deans</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "2vw"}}>
      <AppBar position="relative"style={{ background: 'white', height: "5vw", marginTop: "1vw"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem onClick={handleBoard}>Board of Regents</MenuItem>
        <MenuItem onClick={handlePresident}>The President</MenuItem>
        <MenuItem onClick={handleVPresident}>Management Committee</MenuItem>
        <MenuItem onClick={handleDirectorChief}>Directors and Chiefs</MenuItem>
        <MenuItem
          onClick={() =>
            dean.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          Deans
        </MenuItem>
        <MenuItem onClick={handleOrgChart}>Organizational Chart</MenuItem>
        <MenuItem onClick={handleSupportStaff}>Presidential Support Staff</MenuItem>
        <MenuItem onClick={handlePastPresident}>Past University Presidents</MenuItem>
      </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box ref={dean} sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '2vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean1} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean2} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean3} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean4} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Prof. Luzviminda B. Landicho</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Arch. Jared Aaron R. Cruz</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Engr. Bernard R. Letrero</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Dr. Jimmy M. Romero</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">lblandicho@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">jarcruz@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">brletrero@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">jmromero@plm.edu.ph</Typography></Button></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Dean, College of Accountancy</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Acting Dean, College of Architecture and Sustainable Built Environment</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Dean, College of Business Administration</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Dean, College of Education</Typography></Box>
      </Box>

      <Box ref={dean} sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '2vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean5} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean6} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean7} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean8} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Engr. Evangeline P. Lubao</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Prof. Luningning P. Galindez</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Dr. Khatalyn E. Mata</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Atty. Magelio S. Arboladura</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">eplubao@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">lpgalindez@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">kemata@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">msarboladura@plm.edu.ph</Typography></Button></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Acting Dean, College of Engineering</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Acting Dean, College of Humanities, Arts and Social Sciences</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Acting Dean, College of Information Systems and Technology Management</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Dean, College of Law</Typography></Box>
      </Box>

      <Box ref={dean} sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '2vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean9} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean10} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean11} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean12} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Dr. Rose Anna R. Banal</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Dr. David Paul R. Ramos</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Dr. Nil Edward F. Panuelos</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Prof. Noemi C. Gocuyo</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">rarbanal@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">dprramos@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">nefpanuelos@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">ncgocuyo@plm.edu.ph</Typography></Button></Box>
      </Box>

      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Dean, College of Medicine</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Dean, College of Nursing</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Dean, College of Physical Therapy</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Acting Dean, College of Public Administration</Typography></Box>
      </Box>

      <Box ref={dean} sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '2vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean13} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean14} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean15} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={dean16} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Dr. Aileen I. Atienza</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Prof. Jerrald Lancer O. Abril</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Dr. Joseph Berlin P. Juanzon</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Justice Hector L. Hofileña</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">aiatienza@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">jloabril@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">jbpjuanzon@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">hlhofilena@plm.edu.ph</Typography></Button></Box>
      </Box>

      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Dean, College of Science</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Acting Dean, College of Tourism and Hospitality Management</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Executive Director of the Office for Graduate and Professional Studies</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Dean, Graduate School of Law</Typography></Box>
      </Box>

      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default Dean;
