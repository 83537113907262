import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Logo from './img/plmlogo1.png';
import FacebookIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import EmailIcon from '@mui/icons-material/EmailRounded';
import Logosmall from './img/smalllogo.png';
import ChatBot from "./components/ChatBot";
import Fab from '@mui/material/Fab';
import ChatIcon from '@mui/icons-material/Chat';
import { Unstable_Popup as BasePopup} from '@mui/base/Unstable_Popup';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Modal } from '@mui/material';

function Headers() {
  const handleFacebook = () => {
    window.location.href="https://www.facebook.com/PLM.Haribon";
  };

  const handleTwitter = () => {
    window.location.href="https://twitter.com/PLM_Manila";
  };

  const handleInstagram = () => {
    window.location.href="https://www.instagram.com/PLM_Intramuros/";
  };

  const handleLogo = () => {
    window.location.href="https://plm.edu.ph";
  };

  const [anchorAbout, setAnchorAbout] = React.useState<null | HTMLElement>(null);
  const [anchorAcademics, setAnchorAcademics] = React.useState<null | HTMLElement>(null);
  const [anchorAdmission, setAnchorAdmission] = React.useState<null | HTMLElement>(null);
  const [anchorNews, setAnchorNews] = React.useState<null | HTMLElement>(null);
  const [anchorStudent, setAnchorStudent] = React.useState<null | HTMLElement>(null);
  const [anchorFaculty, setAnchorFaculty] = React.useState<null | HTMLElement>(null);
  const openAbout = Boolean(anchorAbout);
  const openAcademics = Boolean(anchorAcademics);
  const openAdmission = Boolean(anchorAdmission);;
  const openNews = Boolean(anchorNews);;
  const openStudent = Boolean(anchorStudent);;
  const openFaculty = Boolean(anchorFaculty);;

  const handleAbout = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorAbout(event.currentTarget);
  };

  const handleAcademics = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorAcademics(event.currentTarget);
  };

  const handleAdmission = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorAdmission(event.currentTarget);
  };

  const handleNews = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorNews(event.currentTarget);
  };

  const handleStudent = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorStudent(event.currentTarget);
  };
  const handleFaculty = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorFaculty(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorAbout(null);
    setAnchorAcademics(null);
    setAnchorAdmission(null);
    setAnchorNews(null);
    setAnchorStudent(null);
    setAnchorFaculty(null);
  };

  const handleHome = () => {
    window.location.href="./";
  };

  const handleUP = () => {
    window.location.href="./UniversityProfile#vision";
  };

  const handleAdministration = () => {
    window.location.href="./Administration";
  };

  const handleContact = () => {
    window.location.href="./ContactCollege";
  };

  const handleOffice = () => {
    window.location.href="./OfficeOSDS";
  };

  const handleCollegeCASBE = () => {
    window.location.href="./CollegeCASBE";
  };

  const handleCalendar = () => {
    window.location.href="./Calendar";
  };

  const handleGraduate = () => {
    window.location.href="./Graduate";
  };

  const handleOBE = () => {
    window.location.href="./OBE";
  };
  const handleCRS = () => {
    window.location.href="./CRS";
  };
  const handleSFE = () => {
    window.location.href="https://web2.plm.edu.ph/sfe/";
  };

  const handlePLMAT = () => {
   window.location.href="./PLMAT";
   //window.location.href="./img/PLMAT2025.pdf";
  };

  const handleCMAT = () => {
    window.location.href="./CMAT";
  };

  const handleCLAT = () => {
    window.location.href="./CLAT";
  };

  const handleScholarship = () => {
    window.location.href="./Scholarship";
  };

  const handleDownload = () => {
    window.location.href="./Download";
  };

  const handleGallery = () => {
    window.location.href="./Gallery";
  };

  const handleAnnouncement = () => {
    window.location.href="./Announcement";
  };

  const handleAlumni = () => {
    window.location.href="https://web2.plm.edu.ph/ars/";
  };

  const handlePartner = () => {
    window.location.href="./Partner";
  };

  const handleOPAC = () => {
    window.location.href="https://library.plm.edu.ph/";
  };

  const handleEmail = () => {
    window.location.href="mailto:ithelp@plm.edu.ph?subject=Website Inquiry&body=Hello ithelp";
  };

  const settings = {
    general: {embedded: true},
    chatHistory: {storageKey: "playground"},
    botBubble: {simStream: true}
  }

	const helpOptions = ["PLMAT", "Student Portal", "Faculty Portal", "Offices", "Colleges"];
	const flow = {
		start: {
			message: "Hello, Visitor! Welcome to P.L.M., We are pleased to assist you!",
			transition: {duration: 1000},
			path: "show_options"
		},
		show_options: {
			message: "Click an option below:",
			options: helpOptions,
			path: "process_options"
		},
		prompt_again: {
			message: "Do you need any other help?",
			options: helpOptions,
			path: "process_options"
		},
		unknown_input: {
			message: "Sorry, I do not understand your message! If you require further assistance, you may contact " +
				"ithelp@plm.edu.ph",
			options: helpOptions,
			path: "process_options"
		},
		process_options: {
			transition: {duration: 0},
			chatDisabled: true,
			path: async (params: { userInput: any; injectMessage: (arg0: string) => any; }) => {
				let link = "";
				switch (params.userInput) {
				case "PLMAT":
					link = "https://plm.edu.ph/PLMAT";
					break;
				case "Student Portal":
					link = "https://plm.edu.ph/CRS";
					break;
				case "Faculty Portal":
					link = "https://plm.edu.ph/CRS";
					break;
				case "Offices":
					link = "https://plm.edu.ph/OfficeOSDS";
					break;
				case "Colleges":
					link = "https://plm.edu.ph/CollegeCASBE";
					break;
				default:
					return "unknown_input";
				}
				await params.injectMessage("Sit tight! I'll send you right there!");
				setTimeout(() => {
					window.open(link);
				}, 1000)
				return "repeat"
			},
		},
		repeat: {
			transition: {duration: 3000},
			path: "prompt_again"
		},
	}


    const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

    const  handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchor(anchor ? null : event.currentTarget);
    };

    const open = Boolean(anchor);
    const id = open ? 'simple-popup' : undefined;

  return (
    <>
      <BasePopup id={id} open={open} anchor={anchor}>
      <ChatBot settings={{general: {embedded: true}, chatInput: {disabled: true}, voice: {disabled: true}, audio: {disabled: false, defaultToggledOn: true}, userBubble: {showAvatar: true},
							botBubble: {showAvatar: true}, chatHistory: {storageKey: "example_faq_bot"}}} flow={flow}/>
      </BasePopup>

    <AppBar position="sticky" style={{ background: 'white', height: "auto" }}>
        <Box sx={{ flexGrow: 0, justifyContent: "flex-end", paddingRight: "1vw", background: '#A31920',  height: '3vh', display: { xs: 'none', md: 'flex' } }}>
          <Button
            id="student"
            aria-controls={openStudent ? 'student' : undefined}
            aria-haspopup="true"
            aria-expanded={openStudent ? 'true' : undefined}
            onClick={handleStudent}
            sx={{flexGrow: 0, height: "3.5vh", fontSize: "1vw", padding: "4vh, 8vw", color: "white"}}
            endIcon={<ExpandMore />}
          >
            Student
          </Button>
          <Menu
            id="student-menu"                            
            anchorEl={anchorStudent}
            open={openStudent}
            onClose={handleClose}
            onMouseLeave={handleClose}
            MenuListProps={{
              'aria-labelledby': 'student-menu',
            }}
          >
            <MenuItem onClick={handleCRS}>Computerized Registration System (CRS)</MenuItem>
            <MenuItem onClick={handleSFE}>Student Faculty Evaluation</MenuItem>
            <MenuItem onClick={handleOPAC}>Online Public Access Catalog</MenuItem>
          </Menu>
          <Button
            id="faculty"
            aria-controls={openFaculty ? 'faculty' : undefined}
            aria-haspopup="true"
            aria-expanded={openFaculty ? 'true' : undefined}
            onClick={handleFaculty}
            // onMouseOver={handleFaculty}
            sx={{flexGrow: 0, height: "3.5vh", fontSize: "1vw", padding: "4vh, 8vw", color: "white"}}
            endIcon={<ExpandMore />}
          >
            Faculty
          </Button>
          <Menu
            id="faculty-menu"                            
            anchorEl={anchorFaculty}
            open={openFaculty}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'faculty-menu',
            }}
          >
            <MenuItem onClick={handleCRS}>Computerized Registration System (CRS)</MenuItem>
            <MenuItem onClick={handleSFE}>Student Faculty Evaluation</MenuItem>
            <MenuItem onClick={handleOPAC}>Online Public Access Catalog</MenuItem>
            {/* <MenuItem onClick={handleContact}>Contact</MenuItem> */}
          </Menu>


          <Button
            id="alumni"
            aria-controls={'alumni'}
            aria-haspopup="true"
            aria-expanded="true"
            onClick={handleAlumni}
            sx={{flexGrow: 0, height: "3.5vh", fontSize: "1vw", padding: "4vh, 8vw", color: "white"}}
          >
            Alumni
          </Button>
          <Button
            id="partner"
            aria-controls={'partner'}
            aria-haspopup="true"
            aria-expanded="true"
            onClick={handlePartner}
            sx={{flexGrow: 0, height: "3.5vh", fontSize: "1vw", padding: "4vh, 8vw", color: "white"}}
          >
            Partner
          </Button>
          <Button
            id="scholarship"
            aria-controls={'scholarship'}
            aria-haspopup="true"
            aria-expanded="true"
            onClick={handleScholarship}
            sx={{flexGrow: 0, height: "3.5vh", fontSize: "1vw", padding: "4vh, 8vw", color: "white"}}
          >
            scholarship
          </Button>
        </Box>
      {/* </Toolbar> */}
    <Box sx={{ flexGrow: 0, justifyContent: "flex-end", background: '#002984', paddingRight: '5vw', height: 'auto', display: { xs: 'none', md: 'flex' } }}>
      <FacebookIcon style={{ color: 'white', padding: ".5vh .5vw"}} onClick={handleFacebook} />
      <InstagramIcon style={{ color: 'white', padding: ".5vh .5vw"}} onClick={handleInstagram} />
      <XIcon style={{ color: 'white', padding: ".5vh .5vw" }} onClick={handleTwitter} />
      <EmailIcon style={{ color: 'white', padding: ".5vh .5vw" }} onClick={handleEmail}/>
      <img src={Logosmall} alt="plm.edu.ph" width="25vw" height="25vh" style={{ color: 'white', padding: ".5vh .5vw"}}/>
      <Fab size="small" color="primary" aria-label="add"  onClick={handleClick} sx={{position: "fixed", bottom: (theme) => theme.spacing(2), right: (theme) => theme.spacing(2)}}>
        <ChatIcon />
      </Fab>

    </Box>
    <Box sx={{ flexGrow: 0, justifyContent: "flex-end", background: '#F7BE08',  height: 'auto', display: { xs: 'none', md: 'flex' } }} />
    <Box sx={{direction: 'row', textAlign: 'right', height: 'auto', display: 'flex'}}>
              <Box width="10vw"></Box>
              <Box sx={{direction: 'column', textAlign: 'left', maxWidth: "100vw", paddingTop: ".5vw", paddingLeft: "3vw"}}>
                <img src={Logosmall} width="75vw" height="auto" alt="plm.edu.ph" onClick={handleLogo}/>
              </Box>
              <Box sx={{direction: 'column', textAlign: 'left', paddingTop: ".5vw", paddingLeft: "3vw"}}>
              <Typography sx={{ fontWeight: '650', fontSize: "1.75vw", textShadow: ".1vw .1vw .2vw #B31A08" }} color="#FFC000">PAMANTASAN NG LUNGSOD NG MAYNILA</Typography>
              <Typography color="black" sx={{ fontSize: "1.25vw"}}>University of the City of Manila</Typography>
              </Box>
              <Box sx={{display: 'flex', justifyContent: 'flex-end', textAlign: 'flex-end', width: "30vw"}}>
                <Typography color='#B31A08' sx={{ fontSize: "1vw"}} padding="3.6vh 0vw 0vh">Philippine Standard Time: </Typography>
                <br/><br/>
                <Typography color='#B31A08' sx={{ fontSize: "1vw"}} padding="3.6vh 0vw 0vh">{new Date().toLocaleString()}</Typography>
              </Box>``
              <Box width="0vw"></Box>
    </Box>
    <Box sx={{ flexGrow: 1, background: '#002984', height: "auto", padding: ".5vh .5vw .5vh .5vw", display: { xs: 'none', md: 'flex' } }} >
        <Box sx={{ flexGrow: 0,  height: "auto", paddingLeft: '10%'}}>
          <Button
            id="home"
            aria-controls={'home'}
            aria-haspopup="true"
            aria-expanded="true"
            onClick={handleHome}
            sx={{color: 'white'}}
          >
            <Typography fontSize="1vw">HOME</Typography>
          </Button>
           <Button
            id="about"
            aria-controls={openAbout ? 'about' : undefined}
            aria-haspopup="true"
            aria-expanded={openAbout ? 'true' : undefined}
            onClick={handleAbout}
            // onMouseOver={handleAbout}
            sx={{color: 'white'}}
            endIcon={<ExpandMore />}
          >
            <Typography fontSize="1vw">ABOUT</Typography>
          </Button>
          <Menu
            id="about-menu"                            
            anchorEl={anchorAbout}
            open={openAbout}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'about-menu',
            }}
          >
            <MenuItem onClick={handleUP}>University Profile</MenuItem>
            <MenuItem onClick={handleAdministration}>Administration</MenuItem>
            <MenuItem onClick={handleOffice}>Offices</MenuItem>
            <MenuItem onClick={handleContact}>Contact</MenuItem>
          </Menu>
          <Button
            id="academics"
            aria-controls={openAcademics ? 'academics' : undefined}
            aria-haspopup="true"
            aria-expanded={openAcademics ? 'true' : undefined}
            onClick={handleAcademics}
            sx={{color: 'white'}}
            endIcon={<ExpandMore />}
          >
            <Typography fontSize="1vw">ACADEMICS</Typography>
          </Button>
          <Menu
            id="academics-menu"
            anchorEl={anchorAcademics}
            open={openAcademics}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'academics-menu',
            }}
          >
            <MenuItem onClick={handleCollegeCASBE}>Colleges</MenuItem>
            <MenuItem onClick={handleGraduate}>Office for Graduate and Professional Studies</MenuItem>
            <MenuItem onClick={handleCalendar}>Academic Calendar</MenuItem>
            <MenuItem onClick={handleOBE}>Outcomes-Based Education (OBE)</MenuItem>
          </Menu>
          <Button
            id="admission"
            aria-controls={openAdmission ? 'admission' : undefined}
            aria-haspopup="true"
            aria-expanded={openAdmission ? 'true' : undefined}
            onClick={handleAdmission}
            sx={{color: 'white'}}
            endIcon={<ExpandMore />}
          >
            <Typography fontSize="1vw">ADMISSIONS</Typography>
          </Button>
          <Menu
            id="admission-menu"
            anchorEl={anchorAdmission}
            open={openAdmission}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'admission-menu',
            }}
          >
            <MenuItem onClick={handlePLMAT}>PLM Admission Test (PLMAT)</MenuItem>
            <MenuItem onClick={handleCMAT}>College of Medicine Admission Test</MenuItem>
            <MenuItem onClick={handleCLAT}>College of Law Admission Test (CLAT)</MenuItem>
          </Menu>
          <Button
            id="news"
            aria-controls={'news'}
            aria-haspopup="true"
            aria-expanded={'true'}
            onClick={handleAnnouncement}
            sx={{color: 'white'}}
          >
            <Typography fontSize="1vw">ANNOUNCEMENTS</Typography>
          </Button>
          <Button
            id="download"
            aria-controls={'download'}
            aria-haspopup="true"
            aria-expanded="true"
            onClick={handleDownload}
            sx={{color: 'white'}}
          >
            <Typography fontSize="1vw">DOWNLOADS</Typography>
          </Button>

        </Box>
      </Box>
      </AppBar>
      </>
  );
}
export default Headers;