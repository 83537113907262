import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import past1 from './img/benitoreyes.png';
import past2 from './img/consueloblanco.png';
import past3 from './img/ramonbagatsing.png';
import past4 from './img/josevillanueva.png';
import past5 from './img/benjamintayabas.png';
import past6 from './img/virselydelacruz.png';
import past9 from './img/adeltamano.png';
import past11 from './img/artemiotuquero.png';
import past12 from './img/leonoradejesus.png';
import past13 from './img/emmanuelleyco.jpg';
import { useRef } from 'react';

const PastPresident = () => {
  const past = useRef(document.createElement("p"));
  const handleBoard = () => {
    window.location.href="./Administration";
  };

  const handlePresident = () => {
    window.location.href="./President";
  };

  const handleVPresident = () => {
    window.location.href="./VPresident";
  };

  const handleDirectorChief = () => {
    window.location.href="./DirectorChief";
  };

  const handleDean = () => {
    window.location.href="./Dean";
  };

  const handleOrgChart = () => {
    window.location.href="./OrgChart";
  };

  const handleSupportStaff = () => {
    window.location.href="./SupportStaff";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Past University Presidents</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "2vw"}}>
      <AppBar position="relative"style={{ background: 'white', height: "5vw", marginTop: "1vw"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem onClick={handleBoard}>Board of Regents</MenuItem>
        <MenuItem onClick={handlePresident}>The President</MenuItem>
        <MenuItem onClick={handleVPresident}>Management Committee</MenuItem>
        <MenuItem onClick={handleDirectorChief}>Directors and Chiefs</MenuItem>
        <MenuItem onClick={handleDean}>Deans</MenuItem>
        <MenuItem onClick={handleOrgChart}>Organizational Chart</MenuItem>
        <MenuItem onClick={handleSupportStaff}>Presidential Support Staff</MenuItem>
        <MenuItem
          onClick={() =>
            past.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          Past University Presidents
        </MenuItem>
        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box ref={past} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      {/* <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Past University Presidents</Typography>
      </Box> */}
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Get to know the men and women whose vision and leadership shaped PLM to become the renowned and respected university that it is today.</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={past1} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={past2} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={past3} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={past4} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={past5} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= ".75vw">DR. BENITO REYES</Typography></Box>
        <Box width="20%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= ".75vw">DR. CONSUELO S. BLANCO</Typography></Box>
        <Box width="20%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= ".75vw">HON. MAYOR RAMON BAGATSING</Typography></Box>
        <Box width="20%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= ".75vw">DR. JOSE D. VILLANUEVA</Typography></Box>
        <Box width="20%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= ".75vw">DR. BENJAMIN D. TAYABAS</Typography></Box>
      </Box>
      <br/>

      {/* <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 150px 0px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Feb. 23, 1967 to Jun 23, 1972</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Dec 21, 1972 to May 31, 1978</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>June 1, 1978 to Oct 27, 1982</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Jan 14, 1983 to Jun 30, 1989</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Jul 2, 1989 to Jun 24, 1996</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 150px 0px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Feb 23, 2000 to Feb 22, 2006</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 150px 0px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}></Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Jun 2, 2006 to Aug 1, 2007</Typography>
      </Box> */}


      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={past6} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={past9} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={past11} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={past12} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={past13} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= ".75vw">DR. VIRSELY M. DELA CRUZ</Typography></Box>
        <Box width="20%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= ".75vw">ATTY. ADEL A. TAMANO</Typography></Box>
        <Box width="20%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= ".75vw">JUSTICE ARTEMIO G. TUQUERO</Typography></Box>
        <Box width="20%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= ".75vw">DR. MA. LEONORA V. DE JESUS</Typography></Box>
        <Box width="20%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= ".75vw">PROF. EMMANUEL A. LEYCO</Typography></Box>
      </Box>

      {/* <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 150px 0px 256px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Jun 25, 1996 to April 30, 1999</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Aug 9, 2007 to Nov 31, 2009</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Jul 3, 2013 to Sep 17, 2014</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>Sep 2014 to Jun 2019</Typography>
        <Typography variant="subtitle2" width="20%" sx={{ flexGrow: 0, textAlign: "center", fontSize: "12px", fontWeight: 'bold' }}>July 1, 2019 to October 15, 2023</Typography>
      </Box> */}
      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default PastPresident;
