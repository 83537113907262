import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import { useRef } from 'react';

const Hymn = () => {
  const hymn = useRef(document.createElement("p"));

  const handleUniversityProfile = () => {
    window.location.href="./UniversityProfile";
  };

  const handleGoals = () => {
    window.location.href="./Goals";
  };

  const handleHistory = () => {
    window.location.href="./History";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">University Hymn</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "2vw"}}>
      <AppBar position="relative"style={{ background: 'white', height: "5vw", marginTop: "1vw"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
          <MenuItem onClick={handleUniversityProfile}>Vision / Mission</MenuItem>
          <MenuItem onClick={handleGoals}>Goals / Key Result Areas</MenuItem>
          <MenuItem onClick={handleGoals}>Seal and Symbols</MenuItem>
          <MenuItem onClick={handleHistory}>PLM History</MenuItem>
          <MenuItem
            onClick={() =>
              hymn.current.scrollIntoView({
                behavior: "smooth",
                block: "start"       
              })
            }
          >
            University Hymn
          </MenuItem>
        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box ref={hymn} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      {/* <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Music helps unite the PLM community. Every Monday and official school activity, students, faculty, and staff alike sing in unison the “Pamantasang Mahal”, PLM’s official hymn.</Typography>
      </Box> */}
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Lyrics: Gatpuno Antonio J. Villegas, Mayor of Manila (1968)<br/>Lyrics presented to the Board of Regents in Maharnilad (official name of the famous Manila City Hall).</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Music: Prof. Felipe Padilla de Leon</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Pamantasang Mahal</Typography>
      </Box>
      <br/> 
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Official hymn of the Pamantasan ng Lungsod ng Maynila</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Pamantasan, Pamantasang Mahal<br/>Nagpupugay kami't nag-aalay<br/>Ng Pag-ibig, taos na paggalang<br/>Sa patnubay ng aming isipan.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Karunungang tungo'y kaunlaran<br/>Hinuhubog kaming kabataan<br/>Maging Pilipinong mero'ng dangal<br/>Puso'y tigib ng kadakilaan.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Pamantasang Lungsod ng Maynila<br/>Kaming lahat dito'y iyong punla<br/>Tutuparin pangarap mo't nasa<br/>Pamantasan kami'y nanunumpa.<br/>Pamantasan kami'y nanunumpa.</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "center", padding: '0vw 10vw 0vw 10vw', height: '50%', display: { xs: 'none', md: 'flex' } }}>
        <iframe 
          title="Pamantasang Mahal"
          width="560" 
          height="315" 
          src="https://youtu.be/yPf9vKW04cs" 
          data-allow="autoplay; encrypted-media" 
          allowFullScreen
        >
        </iframe>
      </Box>
      <br/>
      {/* <Box sx={{ flexGrow: 0, justifyContent: "center", background: '#002984', padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h4" alignContent="center" color="white">University Charter</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Republic Act No. 4196 or “An Act Authorizing the City of Manila to Establish and Operate the University of City of Manila”, which created the PLM, is the University Charter. It was signed into law on June 19, 1965.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">According to the charter, the Congress of the Philippines conferred upon the Board of Regents the exclusive power to govern the university.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Photo: President Diosdado Macapagal signs Republic Act No. 4196, otherwise known as the University Charter. Joining him are (left to right) Atty. Primitivo de Leon, secretary to the Mayor of Manila; Manila Mayor Antonio Villegas (standing, second from left); Rep. Ramon Mitra, Jr.; and Rep. Justo Albert (right), principal sponsor of the bill.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "center", background: '#002984', padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h4" alignContent="center" color="white">University Code</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">The University Code of 2005 was approved by the Board of Regents under Resolution No. 2823 during its 379th regular meeting held on October 28, 2005.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Pursuant to Board Resolution No. 3697 which the Board has approved on 09 October 2014, the University Code of 2005 shall be adopted as the continuing, operative University Code and that said Code is, in itself, the implementing guideline.</Typography>
      </Box>      
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <a href={UniversityCode} download="UniversityCode" target='_blank'>
          <Button>Download University Code</Button>
        </a>
      </Box>      
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "center", background: '#002984', padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h4" alignContent="center" color="white">Academic Thrusts</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">PLM strives to fulfill the following to ensure that its students, faculty, and staff are among the best, brightest, and most compassionate:</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">a. Education is a right and an equalizer<br/>b. Quality education upheld<br/>c. Catalyst and resource for good governance<br/>d. Mobilizing resources efficiently<br/>e. Keeping academic freedom, creative thinking, and collective action alive<br/>f. Continuous faculty and staff development</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "center", background: '#002984', padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h4" alignContent="center" color="white">Facilities</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Visit our historic campus and be among the few privileged to study in the heritage capital of the Philippines.</Typography>
      </Box>
      <br/> */}

      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default Hymn;
