import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CLAT = () => {
  const clat = useRef(document.createElement("p"));

  const handlePLMAT = () => {
    window.location.href="./PLMAT";
  };
  const handleCMAT = () => {
    window.location.href="./CMAT";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">College of Law Admission Test (CLAT)</Typography>
      </Box>      
      <br/>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "10%", paddingLeft: "15px"}}>
      <AppBar position="relative"style={{ background: 'white', height: "5vw", marginTop: "1vw"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem onClick={handlePLMAT}>PLM Admission Test (PLMAT)</MenuItem>
        <MenuItem onClick={handleCMAT}>College of Medicine Admission Test (CMAT)</MenuItem>
        <MenuItem
          onClick={() =>
            clat.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          College of Law Admission Test (CLAT)
        </MenuItem>
        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "90%"}}>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '5px', display: { xs: 'none', md: 'flex' } }} />

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">The College of Law Admission Test (CLAT) is a requirement for applicants of the Juris Doctor (J.D.) program of the PLM College of Law.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="left">Admission Requirements</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">1. A general Weighted Average (GWA) of at least 2.00 or (84%) in the bachelor’s degree.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">2. Applicants must have earned 18 units of English, 6 units of Mathematics, 18 units of Social Science subjects. Applicants lacking units must satisfy these requirements during summer before they are allowed admission.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">3. Manila residency 43 evidenced by the applicant’s voter’s ID/certification, and a Barangay certification (for Manila’s partial scholars only). Non-residents of Manila may apply as regular full-paying students.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">4. Official Transcript of Records (TOR) from a recognized university or college.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">5. Any government-issued ID (competent evidence of identification) and/or CTC.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">6. Income tax return of applicant or the applicant’s parent from the year prior to the date of application.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">7. Two (2) recent passport size, identical colored photos.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">8. A letter of recommendation from the Dean or Head of former school.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="left">Application process</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">1. Submit requirements to the College for Committee on Admission and for interview to make initial screening according to the criteria on admission.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">2. Application form is issued to applicants who meet the criteria for admission and completed documents are returned.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">3. Pay the non-refundable CLAT fee via the following:<br/>
a. Land Bank of the Philippines' LinkBiz Portal<br/>b. Bank deposit<br/>
     Account name: Pamantasan ng Lungsod ng Maynila<br/>
     Account number: LBP–2472 1006 56</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">4. Submit the filled-out application form together with requirements.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">5. Applicant who passes the CLAT will be informed of the date of interview. Non-response within five (5) days and/or his/her non-appearance will cause forfeiture of slot in the College.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">6. Qualified applicants will be informed of enrollment schedule. The list of successful freshmen will be posted in the College of Law.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="left">Acceptance to the college will be based on the satisfaction of the following:</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">1. Compliance with admission requirements.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">2. Submission of required documents.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">3. Passing of the College of Law Admission Test (CLAT).</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">4. Personal interview and evaluation by the Committee on Admissions.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">The Committee on Admission reserves the right to deny the admission of an applicant if the requirements and all other admission criteria are not satisfactorily complied with.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">Admission decision will be based on the result of the evaluation of all the required documents, as well as the entrance examination and the interview. The Committee on Admission shall submit to the Dean of the College its recommendation for his review and final recommendation to the President of the University.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">Any evidence of fraud, misrepresentation, or non-disclosure by the applicant of any information relative to the requirements shall be ground for the cancellation of his admission or enrollment.</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">For more info, contact the PLM College of Law:</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">Email: <a href="mailto:clgroup@plm.edu.ph">clgroup@plm.edu.ph</a></Typography>
      </Box>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CLAT;
