import { AppBar, Button, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import vp1 from './img/administration/Dr. Ronald P. Romero.png';
import vp2 from './img/administration/Dr. Ronaldo A. Tan.png';
import vp3 from './img/administration/Ms. Luzviminda B. Landicho, MBA, CPA.png';
import vp4 from './img/administration/Dr. Dan Michael A. Cortez.png';
import mc1 from './img/administration/Mr. Reynaldo J. Villegas.png';
import mc2 from './img/administration/Atty. Carlo Florendo C. Castro, University Board Secretary.png';
import mc3 from './img/administration/Benedicto L. Avila.png';
import mc4 from './img/administration/Atty. Relson P. Moral.png';
import mc5 from './img/administration/Mr. Ryan Jay SM. Cruz.png';
import EmailIcon from '@mui/icons-material/EmailRounded';

import { useRef } from 'react';

const VPresident = () => {
  const board = useRef(document.createElement("p"));
  const vp = useRef(document.createElement("p"));
  const handleBoard = () => {
    window.location.href="./Administration";
  };

  const handlePresident = () => {
    window.location.href="./President";
  };

  const handleDirectorChief = () => {
    window.location.href="./DirectorChief";
  };

  const handleDean = () => {
    window.location.href="./Dean";
  };

  const handleOrgChart = () => {
    window.location.href="./OrgChart";
  };

  const handleSupportStaff = () => {
    window.location.href="./SupportStaff";
  };

  const handlePastPresident = () => {
    window.location.href="./PastPresident";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Management Committee</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "2vw"}}>
      <AppBar position="relative"style={{ background: 'white', height: "5vw", marginTop: "1vw"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem onClick={handleBoard}>Board of Regents</MenuItem>
        <MenuItem onClick={handlePresident}>The President</MenuItem>
        <MenuItem
          onClick={() =>
            vp.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          Management Committee
        </MenuItem>
        <MenuItem onClick={handleDirectorChief}>Directors and Chiefs</MenuItem>
        <MenuItem onClick={handleDean}>Deans</MenuItem>
        <MenuItem onClick={handleOrgChart}>Organizational Chart</MenuItem>
        <MenuItem onClick={handleSupportStaff}>Presidential Support Staff</MenuItem>
        <MenuItem onClick={handlePastPresident}>Past University Presidents</MenuItem>
        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box ref={vp} sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '2vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={vp1} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={vp2} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={vp3} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={vp4} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Dr. Ronald P. Romero</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Dr. Ronald A. Tan</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Ms Luzviminda B. Landicho</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Dr. Dan Michael A. Cortez</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">rpromero@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">ratan@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">lblandicho@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">dmacortez@plm.edu.ph</Typography></Button></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Vice President for Academic Affairs</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Vice President for Administration</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Vice President for Finance</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Vice President for Research Academic and Extension Services</Typography></Box>
      </Box>

      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '2vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={mc1} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={mc2} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={mc3} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={mc4} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={mc5} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Mr. Reynaldo J. Villegas</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Atty. Carlo Florendo C. Castro</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Dr. Benedicto L. Avila</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Atty. Relson P. Moral</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Mr. Ryan Jay SM. Cruz</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">rjvillegas@plm.edu.ph</Typography></Button></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">cfcastro@plm.edu.ph</Typography></Button></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">blavila@plm.edu.ph</Typography></Button></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">rpmoral@plm.edu.ph</Typography></Button></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">rjsmcruz@plm.edu.ph</Typography></Button></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Chief, Human Resource Management Office</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Chief, Office of the University Legal Counsel</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">University Registrar</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Chief Executive Staff</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Head, Executive Assistant </Typography></Box>
      </Box>

      <br/>

      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default VPresident;
