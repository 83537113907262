import './App.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import aboutcard from './img/aboutcard.jpg';
import Button from '@mui/material/Button';

const AboutCard = () => {
  return (
    <>
      <hr/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '1vw 2vh', height: 'auto', display: { xs: 'none', md: 'flex' } }}>
        <Typography color="#A31920" variant="h5">ABOUT THE UNIVERSITY</Typography><br/>
      </Box>
    <Grid container>
      <Grid item xs={4} alignContent="right "padding="1vh 2vw 1vw 2vw">
        <a href="#"><img src={aboutcard} alt="About the University" height="auto" width="75%" style={{ margin: "1px"}}/></a>
      </Grid>
      <Grid item xs={8} padding="1vh 2vw 1vw 0vw">
        <Typography alignItems="left" fontSize= "1.25vw">Pamantasan ng Lungsod ng Maynila (PLM) is the first and only chartered and autonomous university funded by a city government. It was created by the Congress of the Philippines by virtue of Republic Act No. 4196 or “An Act Authorizing the City of Manila to Establish and Operate the University of City of Manila” on June 19, 1965.</Typography>
        <br/>
        <Typography alignItems="left" fontSize= "1.25vw">The university first opened its gates on July 17, 1967 to 556 first-year students at its campus in the historic Intramuros district, which served as the seat of power during the Spanish occupation. Currently, about 10,000 graduate and post-graduate students grace its halls to receive PLM’s quality education.</Typography>
        <br/>
        <Button href="./UniversityProfile"><Typography fontSize= "1.25vw">Read More</Typography></Button>
      </Grid>
    </Grid>
    </>
  );
}

export default AboutCard;
