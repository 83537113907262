import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography } from '@mui/material';

import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
function Career1() {
  type resultProps = {
    id: string;
    title: string;
    imagelink: string;
    validuntil: string;
    readmorelink: string;
    description: string;
    description1: string;
    description2: string;
    date: string
  };
  
  function createData(
    title: string,
    link: string,
  ) {
    return { title, link };
  }
  
  const row2 = [
    createData('COMPUTER OPERATOR - University Library', './img/ComputerOperator - University Library.pdf'), 
    createData('UNIQLO Store Partner', 'https://forms.gle/9pHxAcPkbMNwBAXV6'), 
    createData('INFORMATION OFFICER II - Resource Generation Office', './img/RGO(COS).pdf'),   
    createData('FACULTY HIRING - College of Engineering', './img/CE(COS).pdf'),  
    createData('FACULTY HIRING - National Service Training Program', './img/nstp.pdf'), 
    createData('19 PART-TIIME FACULTY HIRING - CHASS', './img/CHASS-Hiring of 19 Part time faculty 2024.pdf'),  
  ];
  const [result, setResult] = useState<resultProps[]>([]);
  
  const API_BASE_URL = "https://plm.edu.ph";

  const fetchCareer = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/plmapi/career.php`);
      setResult(response.data);
      console.log(response.data);
    } catch (err: any) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    fetchCareer();
    // eslint-disable-next-line
  }, []);
 return (
  <>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 96px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Career</Typography>
      </Box>
      <br/>

      <br/>
        <ThemeProvider
          theme={{
            palette: {
              primary: {
                main: '#007FFF',
                dark: '#0066CC',
              },
            },
          }}
        >
          {result && result.map((row) => (
            <Box
              sx={{
                width: 100,
                height: 100,
                borderRadius: 1,
                bgcolor: 'primary.main',
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            >
              <a href={row.imagelink}><Typography>{row.title}</Typography></a>
            </Box>
          ))}
        </ThemeProvider>
      <br/>
      <br/>
      <br/>
      <br/>
      <Footer/>
       </>
 );
}

export default Career1;
