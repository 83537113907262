import { Route, Routes } from "react-router-dom";
import Home from "./Home";
import UniversityProfile from "./UniversityProfile";
import Administration from "./Administration";
import PrideHall from "./PrideHall";
import Contact from "./Contact";
import CollegeCASBE from "./CollegeCASBE";
import Calendar from "./Calendar";
import Graduate from "./Graduate";
import OBE from "./OBE";
import CRS from "./CRS";
import PLMAT from "./PLMAT";
import CMAT from "./CMAT";
import CLAT from "./CLAT";
import GP from "./GP";
import UGP from "./UGP";
import Scholarship from "./Scholarship";
import Research from "./Research";
import Download from "./Download";
import Press from "./Press";
import Gallery from "./Gallery";
import Report from "./Report";
import Newsletter from "./Newsletter";
import Announcement from "./Announcement";
import Award60th from "./Award60th";
import Message from "./Message";
import Student from "./Student";
import Faculty from "./Faculty";
import Staff from "./Staff";
import Alumni from "./Alumni";
import Partner from "./Partner";
import Event from "./Event";
import Procurement from "./Procurement";
import Career from "./Career";
import Career1 from "./Career1";
import SFE from "./SFE";
import OPAC from "./OPAC";
import PrivacyPolicy from "./PrivacyPolicy";
import Term from "./Term";
import Bids from "./Bids";
import Goals from "./Goals";
import Seal from "./Seal";
import History from "./History";
import Hymn from "./Hymn";
import President from "./President";
import VPresident from "./VPresident";
import DirectorChief from "./DirectorChief";
import Dean from "./Dean";
import OrgChart from "./OrgChart";
import SupportStaff from "./SupportStaff";
import PastPresident from "./PastPresident";
import ContactCollege from "./ContactCollege";
import ContactOffice from "./ContactOffice";
import ContactExecutive from "./ContactExecutive";
import CollegeCA from "./CollegeCA";
import CollegeCTHM from "./CollegeCTHM";
import CollegeCE from "./CollegeCE";
import CollegeCISTM from "./CollegeCISTM";
import CollegeCED from "./CollegeCED";
import CollegeCHASS from "./CollegeCHASS";
import CollegeNursing from "./CollegeNursing";
import CollegePT from "./CollegePT";
import CollegeScience from "./CollegeScience";
import CollegeLaw from "./CollegeLaw";
import CollegeMedicine from "./CollegeMedicine";
import CollegeBS from "./CollegeBS";
import CollegeGLaw from "./CollegeGLaw";
import CollegePublicHealth from "./CollegePublicHealth";
import CollegeGovernment from "./CollegeGovernment";
import CollegeCBA from "./CollegeCBA";
import OfficeAccounting from "./OfficeAccounting";
import OfficeBudget from "./OfficeBudget";
import OfficeCUES from "./OfficeCUES";
import OfficeGSO from "./OfficeGSO";
import OfficeAudit from "./OfficeAudit";
import OfficeGraduate from "./OfficeGraduate";
import OfficeOGTS from "./OfficeOGTS";
import OfficeNSTP from "./OfficeNSTP";
import OfficeOSDS from "./OfficeOSDS";
import OfficeULC from "./OfficeULC";
import OfficeOUR from "./OfficeOUR";
import OfficeUsec from "./OfficeUSec";
import OfficeVPRAE from "./OfficeVPRAE";
import OfficePFMO from "./OfficePFMO";
import OfficePMO from "./OfficePMO";
import OfficeLawCenter from "./OfficeLawCenter";
import OfficePO from "./OfficePO";
import OfficePSO from "./OfficePSO";
import OfficeRevenue from "./OfficeRevenue";
import OfficeUHS from "./OfficeUHS";
import OfficeLibrary from "./OfficeLibrary";
import OfficeURC from "./OfficeURC";

function App() {
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/UniversityProfile" element={<UniversityProfile/>}/>
          <Route path="/Administration" element={<Administration/>}/>
          <Route path="/PrideHall" element={<PrideHall/>}/>
          <Route path="/Contact" element={<Contact/>}/>
          <Route path="/CollegeCASBE" element={<CollegeCASBE/>}/>
          <Route path="/Calendar" element={<Calendar/>}/>
          <Route path="/Graduate" element={<Graduate/>}/>
          <Route path="/OBE" element={<OBE/>}/>
          <Route path="/CRS" element={<CRS/>}/>
          <Route path="/PLMAT" element={<PLMAT/>}/>
          <Route path="/CMAT" element={<CMAT/>}/>
          <Route path="/CLAT" element={<CLAT/>}/>
          <Route path="/UGP" element={<UGP/>}/>
          <Route path="/GP" element={<GP/>}/>
          <Route path="/Scholarship" element={<Scholarship/>}/>
          <Route path="/Research" element={<Research/>}/>
          <Route path="/Download" element={<Download/>}/>
          <Route path="/Press" element={<Press/>}/>
          <Route path="/Gallery" element={<Gallery/>}/>
          <Route path="/Report" element={<Report/>}/>
          <Route path="/Newsletter" element={<Newsletter/>}/>
          <Route path="/Announcement" element={<Announcement/>}/>
          <Route path="/Award60th" element={<Award60th/>}/>
          <Route path="/Message" element={<Message/>}/>
          <Route path="/Student" element={<Student/>}/>
          <Route path="/Faculty" element={<Faculty/>}/>
          <Route path="/Staff" element={<Staff/>}/>
          <Route path="/Alumni" element={<Alumni/>}/>
          <Route path="/Partner" element={<Partner/>}/>
          <Route path="/Event" element={<Event/>}/>
          <Route path="/Procurement" element={<Procurement/>}/>
          <Route path="/Career" element={<Career/>}/>
          <Route path="/Career1" element={<Career1/>}/>
          <Route path="/SFE" element={<SFE/>}/>
          <Route path="/OPAC" element={<OPAC/>}/>
          <Route path="/Term" element={<Term/>}/>
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy/>}/>
          <Route path="/Bids" element={<Bids/>}/>
          <Route path="/Goals" element={<Goals/>}/>
          <Route path="/Seal" element={<Seal/>}/>
          <Route path="/History" element={<History/>}/>
          <Route path="/Hymn" element={<Hymn/>}/>
          <Route path="/President" element={<President/>}/>
          <Route path="/VPresident" element={<VPresident/>}/>
          <Route path="/DirectorChief" element={<DirectorChief/>}/>
          <Route path="/Dean" element={<Dean/>}/>
          <Route path="/OrgChart" element={<OrgChart/>}/>
          <Route path="/SupportStaff" element={<SupportStaff/>}/>
          <Route path="/PastPresident" element={<PastPresident/>}/>
          <Route path="/ContactCollege" element={<ContactCollege/>}/>
          <Route path="/ContactOffice" element={<ContactOffice/>}/>
          <Route path="/ContactExecutive" element={<ContactExecutive/>}/>
          <Route path="/CollegeCA" element={<CollegeCA/>}/>
          <Route path="/CollegeCTHM" element={<CollegeCTHM/>}/>
          <Route path="/CollegeCE" element={<CollegeCE/>}/>
          <Route path="/CollegeCISTM" element={<CollegeCISTM/>}/>
          <Route path="/CollegeCED" element={<CollegeCED/>}/>
          <Route path="/CollegeCHASS" element={<CollegeCHASS/>}/>
          <Route path="/CollegeNursing" element={<CollegeNursing/>}/>
          <Route path="/CollegePT" element={<CollegePT/>}/>
          <Route path="/CollegeScience" element={<CollegeScience/>}/>
          <Route path="/CollegeLaw" element={<CollegeLaw/>}/>
          <Route path="/CollegeMedicine" element={<CollegeMedicine/>}/>
          <Route path="/CollegeBS" element={<CollegeBS/>}/>
          <Route path="/CollegeGLaw" element={<CollegeGLaw/>}/>
          <Route path="/CollegePublicHealth" element={<CollegePublicHealth/>}/>
          <Route path="/CollegeGovernment" element={<CollegeGovernment/>}/>
          <Route path="/CollegeCBA" element={<CollegeCBA/>}/>
          <Route path="/OfficeAccounting" element={<OfficeAccounting/>}/>
          <Route path="/OfficeBudget" element={<OfficeBudget/>}/>
          <Route path="/OfficeCUES" element={<OfficeCUES/>}/>
          <Route path="/OfficeGSO" element={<OfficeGSO/>}/>
          <Route path="/OfficeAudit" element={<OfficeAudit/>}/>
          <Route path="/OfficeGraduate" element={<OfficeGraduate/>}/>
          <Route path="/OfficeOGTS" element={<OfficeOGTS/>}/>
          <Route path="/OfficeNSTP" element={<OfficeNSTP/>}/>
          <Route path="/OfficeOSDS" element={<OfficeOSDS/>}/>
          <Route path="/OfficeULC" element={<OfficeULC/>}/>
          <Route path="/OfficeOUR" element={<OfficeOUR/>}/>
          <Route path="/OfficeUsec" element={<OfficeUsec/>}/>
          <Route path="/OfficeVPRAE" element={<OfficeVPRAE/>}/>
          <Route path="/OfficePFMO" element={<OfficePFMO/>}/>
          <Route path="/OfficePMO" element={<OfficePMO/>}/>
          <Route path="/OfficeLawCenter" element={<OfficeLawCenter/>}/>
          <Route path="/OfficePO" element={<OfficePO/>}/>
          <Route path="/OfficePSO" element={<OfficePSO/>}/>
          <Route path="/OfficeRevenue" element={<OfficeRevenue/>}/>
          <Route path="/OfficeUHS" element={<OfficeUHS/>}/>
          <Route path="/OfficeLibrary" element={<OfficeLibrary/>}/>
          <Route path="/OfficeURC" element={<OfficeURC/>}/>

          {/* <Route path="/upcoming/:user" element={<Upcoming/>}/>
          <Route path="/record/:user" element={<Record/>}/>
          <Route path="*" element={<NotFound/>}/> */}
        </Routes>
          </div>
  );
}

export default App;
