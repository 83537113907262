import { Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';

const UGP = () => {
  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Undergraduate Programs</Typography>
      </Box>
      
      <br/>
      <br/>
      <br/>
      <Footer/>
    </div>
  );
}

export default UGP;
