import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography } from '@mui/material';

import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import { gridColumnVisibilityModelSelector } from '@mui/x-data-grid';
function Career() {
  type resultProps = {
    id: string;
    title: string;
    imagelink: string;
    validuntil: string;
    readmorelink: string;
    description: string;
    description1: string;
    description2: string;
    date: string
  };
  
  function createData(
    title: string,
    link: string,
  ) {
    return { title, link };
  }
  
  const row2 = [
    createData('COMPUTER OPERATOR - University Library', './img/ComputerOperator - University Library.pdf'), 
    createData('UNIQLO Store Partner', 'https://forms.gle/9pHxAcPkbMNwBAXV6'), 
    createData('INFORMATION OFFICER II - Resource Generation Office', './img/RGO(COS).pdf'),   
    createData('FACULTY HIRING - College of Engineering', './img/CE(COS).pdf'),  
    createData('FACULTY HIRING - National Service Training Program', './img/nstp.pdf'), 
    createData('19 PART-TIIME FACULTY HIRING - CHASS', './img/CHASS-Hiring of 19 Part time faculty 2024.pdf'),  
  ];
  const [result, setResult] = useState<resultProps[]>([]);
  
  // useEffect(() => {
  //   const car = async () => {
  //     const data = await fetch("https://plm.edu.ph/plmapi/career.php", {
  //       method: "GET"
  //     });
  //     const jsonData = await data.json();
  //     setResult(jsonData);
  //   };
  //   car();
  // }, []);

  const API_BASE_URL = "https://plm.edu.ph";

  const fetchCareer = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/plmapi/career.php`);
      setResult(response.data);
      console.log(response.data);
    } catch (err: any) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    fetchCareer();
    // eslint-disable-next-line
  }, []);
 return (
  <>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 96px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Career</Typography>
      </Box>
      <br/>

<TableContainer component={Paper} sx={{paddingLeft: "20%"}}>
      <Table sx={{ minWidth: 650, maxWidth: "50%", border: 1 }} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center"><Typography variant="h5">Opening</Typography></TableCell>
            <TableCell align="center"><Typography variant="h5">Link</Typography></TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {result.map((row) => (
            <TableRow
              key={row.title}
              sx={{ border: 0 }}
            >
              <TableCell component="th" scope="row" align="left">
                {row.title}
              </TableCell>
              <TableCell align="center"><Typography><a href={row.imagelink}>Download</a></Typography></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

      {/* {result && result.map((value) => {
         return (
           <Grid container key={value.id} justifyContent= "center">
             <Grid item xs={12} padding="16px 0px 16px 96px" alignContent="center">
               <Typography variant="h5" alignContent="center">{value.title}</Typography>
               <Typography alignItems="left">{value.description}</Typography>
               <Typography alignItems="left">{value.description1}</Typography>
               <Typography alignItems="left">{value.description2}</Typography>
             </Grid>
           </Grid>);
       })}      */}
      <br/>
      <br/>
      <br/>
      <br/>
      <Footer/>
       </>
 );
}

export default Career;
