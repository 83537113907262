import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import myfile from './Announcement.json';

function Award60th() {
  type resultProps = {
    id: string;
    title: string;
    imagelink: string;
    validuntil: string;
    readmorelink: string;
    description: string;
    description1: string;
    description2: string;
    date: string
  };
  
  const [result, setResult] = useState<resultProps[]>([]);
  
  useEffect(() => {
    const car = async () => {
      const data = await fetch("https://plm.edu.ph/plmapi/news.php", {
      // const data = await fetch("https://19.65.23.253/plmapi/news.php", {
        method: "GET"
      });
      const jsonData = await data.json();
      setResult(jsonData);
    };
    car();
  }, []);

 return (
  <>
        <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Announcements</Typography>
      </Box>
      <br/>
      <Typography fontSize="24px" padding="16px 0px 16px 275px">
        Read the <a href="./img/MONKEY POX.pdf">Monkey Advisory</a> here.
        </Typography>

      {result && result.map((value) => {
         return (
           <Grid container key={value.id}>
             <Grid item xs={2} alignContent="center" padding="16px 0px 16px 24px">
             </Grid>
             <Grid item xs={4} alignContent="center" padding="16px 0px 16px 24px">
               <a href={value.readmorelink} key={value.id}><img src={value.imagelink} alt={value.title} height="250" width="75%"/></a>
             </Grid>
             <Grid item xs={4} padding="16px 0px 16px 0px">
               <Typography alignItems="left">{value.title}</Typography>
               <br />
               <Typography alignItems="left">{value.description}</Typography>
               <br />
               <Typography alignItems="left">{value.description1}</Typography>
               <br />
               <Typography alignItems="left">{value.description2}</Typography>
               <br />
               {/* <Button><a href={value.readmorelink}>Read More</a></Button> */}
             </Grid>
           </Grid>);
       })}     
      <br/>
      <br/>
      <br/>
      <Footer/>
       </>
 );
}

export default Award60th;