import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import board2 from './img/administration/Atty. Domingo Y. Reyes Jr.png';
import { useRef } from 'react';

const President = () => {
  const president = useRef(document.createElement("p"));

  const handleBoard = () => {
    window.location.href="./Administration";
  };

  const handleVPresident = () => {
    window.location.href="./VPresident";
  };

  const handleDirectorChief = () => {
    window.location.href="./DirectorChief";
  };

  const handleDean = () => {
    window.location.href="./Dean";
  };

  const handleOrgChart = () => {
    window.location.href="./OrgChart";
  };

  const handleSupportStaff = () => {
    window.location.href="./SupportStaff";
  };

  const handlePastPresident = () => {
    window.location.href="./PastPresident";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "2vw"}}>
      <AppBar position="relative"style={{ background: 'white', height: "5vw", marginTop: "1vw"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem onClick={handleBoard}>Board of Regents</MenuItem>
        <MenuItem
          onClick={() =>
            president.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          The President
        </MenuItem>

        <MenuItem onClick={handleVPresident}>Management Committee</MenuItem>
        <MenuItem onClick={handleDirectorChief}>Directors and Chiefs</MenuItem>
        <MenuItem onClick={handleDean}>Deans</MenuItem>
        <MenuItem onClick={handleOrgChart}>Organizational Chart</MenuItem>
        <MenuItem onClick={handleSupportStaff}>Presidential Support Staff</MenuItem>
        <MenuItem onClick={handlePastPresident}>Past University Presidents</MenuItem>
        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '4px', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', display: { xs: 'none', md: 'flex' } }}>
        <img src={board2} width="25%" alt=""  style={{  border: "solid 1px blue", margin: "1px"}} />
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">The President</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Atty. Domingo Reyes Jr. elected as the New President of Pamantasan ng Lungsod ng Maynila</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Pamantasan ng Lungsod Maynila is proud to announce Atty. Domingo Reyes, Jr. as the new President of the university. Atty. Reyes is poised to lead PLM to new heights in the years to come with his impressive background and a commitment to excellence in higher education. He is a writer, professor, lecturer, speaker, consultant and PAASCU accreditor, among others, and committed to public service.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">President Reyes, known as “Sir Sonny” to many, will bring a wealth of experience to PLM, having served in various leadership roles within academia, including as a special assistant to the Chancellor at De La Salle College of St. Benilde and special assistant to the Vice Chancellor for Academics. He is also a Correspondent Lawyer and Psychologist, and has worked as a Human Resource Manager in various private companies. He understands the challenges and opportunities that universities are confronted with today.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">President Reyes received his Doctor of Philosophy in Higher Education Management, with Highest Academic Distinction, from Centro Escolar University, his Juris Doctor from Manuel L. Quezon University, and his Master of Laws from Pamantasan ng Lungsod ng Maynila. Sir Sonny is clearly one of our own. His vision for the future of PLM aligns perfectly with our institution's mission and values.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">As President Reyes takes the helm at PLM, together with the newly appointed Board of Regents, Dr. Joseph Berlin Juanzon, Dr. Neri Pescadera, and PLM’s new Board of Regents Chairperson Atty. Edward Serapio. With incumbent Board of Regents Wilma Galvante and Rita Riddle, they will work collaboratively with students, faculty, staff, and the broader community to continue building on the university's proud tradition of academic excellence and innovation. Their leadership will be instrumental in advancing our commitment to fostering a diverse and inclusive campus environment that embraces the full spectrum of human potential.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">President Reyes is set to officially assume the presidency on October 31, 2023, and the entire PLM community looks forward to working closely with them as we undertake on this exciting new chapter in the university's history.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Please join us in extending a warm welcome to President Domingo Reyes Jr. as they take on this pivotal role. We invite you to stay tuned for future updates and announcements as we embark on this new journey together.</Typography>
      </Box>
      <br/>

      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default President;
