import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CollegeScience = () => {

  const science = useRef(document.createElement("p"));

  const handleCollegeCASBE = () => {
    window.location.href="./CollegeCASBE";
  };

  const handleCollegeCBA = () => {
    window.location.href="./CollegeCBA";
  };

  const handleCollegeCA = () => {
    window.location.href="./CollegeCA";
  };

  const handleCollegeCTHM = () => {
    window.location.href="./CollegeCTHM";
  };

  const handleCollegeCE = () => {
    window.location.href="./CollegeCE";
  };

  const handleCollegeCISTM = () => {
    window.location.href="./CollegeCISTM";
  };

  const handleCollegeCED = () => {
    window.location.href="./CollegeCED";
  };

  const handleCollegeCHASS = () => {
    window.location.href="./CollegeCHASS";
  };

  const handleCollegeNursing = () => {
    window.location.href="./CollegeNursing";
  };

  const handleCollegePT = () => {
    window.location.href="./CollegePT";
  };

  const handleCollegeLaw = () => {
    window.location.href="./CollegeLaw";
  };

  const handleCollegeMedicine = () => {
    window.location.href="./CollegeMedicine";
  };

  const handleCollegeBS = () => {
    window.location.href="./CollegeBS";
  };

  const handleCollegeGLaw = () => {
    window.location.href="./CollegeGLaw";
  };

  const handleCollegePublicHealth = () => {
    window.location.href="./CollegePublicHealth";
  };

  const handleCollegeGovernment = () => {
    window.location.href="./CollegeGovernment";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Colleges</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
              <MenuItem onClick={handleCollegeCASBE}>Architecture</MenuItem>
              <MenuItem onClick={handleCollegeCBA}>Business Administration</MenuItem>
              <MenuItem onClick={handleCollegeCA}>Accountancy</MenuItem>
              <MenuItem onClick={handleCollegeCTHM}>Tourism & Hotel Management</MenuItem>
              <MenuItem onClick={handleCollegeCED}>Education</MenuItem>
              <MenuItem onClick={handleCollegeCE}>Engineering</MenuItem>
              <MenuItem onClick={handleCollegeCISTM}>Information System</MenuItem>
              <MenuItem onClick={handleCollegeCHASS}>Humanities, Arts and Social Science</MenuItem>
              <MenuItem onClick={handleCollegeNursing}>Nursing</MenuItem>
              <MenuItem onClick={handleCollegePT}>Physical Therapy</MenuItem>
              <MenuItem
                  onClick={() =>
                    science.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Science
                </MenuItem>
                <MenuItem onClick={handleCollegeLaw}>Law</MenuItem>
                <MenuItem onClick={handleCollegeMedicine}>Medicine</MenuItem>
                <MenuItem onClick={handleCollegeBS}>Business School</MenuItem>
                <MenuItem onClick={handleCollegeGLaw}>Graduate School of Law</MenuItem>
                <MenuItem onClick={handleCollegePublicHealth}>Public Health</MenuItem>
                <MenuItem onClick={handleCollegeGovernment}>Government</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={science} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Paper elevation={2} sx={{padding: '16px'}}>
          
      <Box sx={{ flexGrow: 0, justifyContent: "left", backgroundColor: "#A31920"}}>
        <Typography variant="h5" color="white">College of Science</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The College of Science is a diverse and dynamic academic community advancing scientific knowledge and education through its four departments: Biology, Mathematics, Physical Science, and Psychology. These departments foster collaboration, innovation, and academic excellence, promoting interdisciplinary research to address today’s complex challenges. By equipping students with essential skills, advancing scientific knowledge, and contributing to societal well-being, the college nurtures the next generation of scientists, researchers, and professionals to drive progress on a global scale. 
        </Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">History</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        On March 18, 2002, in a Board of Regents Meeting, “A Proposal to Reorganize the Different Colleges and Academic Units in line with the Restructuring Efforts of the Administration” was presented. One of the proposals was the division of the College of Arts and Sciences in three (3) independent colleges with academic disciplines namely: (a) College of Science: Physical Sciences, Biological Sciences and Mathematics; (b) College of Liberal Arts; and (c) College of Mass Communication.  On April 2, 2002, Board Resolution No. 2467 approved the college separation proposal.  
        <br/>June 4, 2015, during the Special Meeting, re-organization / merging of colleges was approved by the PLM Board of Regents as recommended by the University Council. The College of Science retained its name but with the inclusion of BS Psychology, MA Psychology and MS in Mathematics Education.  The Office of the Science Laboratory Services (SLS) was also merged into the College of Science as one of its unit/ departments. 
        <br/>On 06 April 2018, Board Resolution No. 4448, the proposed curriculum for undergraduate programs were approved: Bachelor of Science in Biology major in Cell and Molecular Biology, Bachelor of Science in Biology major in Ecology, Bachelor of Science in Biology major in Medical Biology, Bachelor of Science in Chemistry, Bachelor of Science in Mathematics, and Bachelor of Science in Psychology. 
        </Typography>
      </Box>      
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Vision</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
          To make the College of Science premier college, a Center of Excellence and frontier of Knowledge in science through rigorous academic training in teaching, research, and overall scholarship that remains relevant to the demand of changing times. By responding to the challenges, needs, and on-going progress of the Filipino people, neighboring countries in Asia and the world at large. PLM will proudly display its innovative brand of leadership both globally and technologically.  
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Mission</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        As a Center of Excellence, the PLM College of Science:   
<br/>- aims to adhere to national and international standards through its multidisciplinary approach for recognition by National and International accrediting agencies;  
<br/>- aims to achieve a higher than national average performance on professional examinations;  
<br/>- aims primarily to training bright Filipino high school students from Manila in overall scholarship, science education, and research for global competitiveness and employability.  
<br/>- encourages and supports research initiatives from faculty and students that will contribute to the advancement of science.  
<br/>- aims to lead the academic community for policy and decision–making that promotes intellectual excellence, integrity and social responsibility.  
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Objectives</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Major:
To equip future professionals with knowledge and skills that can be utilized in the fields of Biology, Chemistry, Mathematics and Psychology.

<br/>Specific:
<br/>1.        To train and acquaint clienteles with the range of the breathes and depths of sciences. 
<br/>2.        To help achieve and inculcate a sense of professionalism. 
<br/>3.        To instill and reinforce values and fine tune their interpersonal and intrapersonal relations. 
<br/>4.        To enable the stakeholders to integrate skills and knowledge with critical thinking, independent judgment and analytical power. 
<br/>5.        To acquire research skills and be able to critically evaluate and apply scientific breakthroughs; and 
<br/>6.        To strengthen a sense of service to the nation's capital.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Public Offering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        A. BACHELOR OF SCIENCE IN BIOLOGY (CHED CMO no. 49 s. 2017). <br/>Biology as an academic discipline has broadened and diversified with the development of advanced laboratory equipment and sophisticated techniques in collaboration with chemists, physicists and engineers. This has led to a dynamic development of research in all levels of biological complexity from the molecules to ecosystems, where their properties and interactions are analysed. 

        <br/>A.1 BACHELOR OF SCIENCE IN BIOLOGY major in Medical Biology (BS BIO - MB) (CHED CMO no. 49 s. 2017).<br/> This program provides students with a strong foundation in the biological sciences, with a focus on human biology and the medical field. The curriculum for the BS Biology major in Medical Biology includes courses such as Human Anatomy and Physiology, Medical Microbiology, Epidemiology, Medical Parasitology, and Medical Histology. The program prepares students for careers in medicine, biomedical research, biotechnology, and other allied health professions. Graduates of the BS Biology major in Medical Biology are well-qualified for a variety of careers in the biological sciences and the medical field. They may work as medical assistants, biomedical researchers, biotechnology scientists, environmental scientists, and science educators. These are just a few of the many career opportunities that graduates of the BS Biology major in Medical Biology may pursue. They may also proceed to graduate studies related to their field.   


        <br/>Graduates of BS Biology major in Medical Biology have a wide range of job opportunities in the medical, research, and educational fields. In addition, they can also find employment in a variety of other settings, such as:  
        <br/>1. Hospitals  
        <br/>2. Clinics  
        <br/>3. Pharmaceutical companies  
        <br/>4. Biotechnology companies  
        <br/>5. Government agencies  
        <br/>6. Academe/ Universities  
        <br/>7. Non-profit organizations  

        <br/>With a strong foundation in biology and medical sciences, graduates of BS Biology major in Medical Biology are well-prepared to make significant contributions to the field of medicine. They are able to conduct research, analyze data, and develop new technologies that can improve human health.  

        <br/>In addition to their technical skills, graduates of BS Biology major in Medical Biology also have strong communication and critical thinking skills. These skills are essential for success in any field, and they make graduates of BS Biology major in Medical Biology valuable assets to any team.  


        <br/>A.2 BACHELOR OF SCIENCE IN BIOLOGY major in Cell and Molecular Biology (BS BIO - CMB) (CHED CMO no. 49 s. 2017). <br/>This program offers students a solid foundation in the biological sciences with a focus on the structure and function of cells and molecules. The program prepares students for careers in research, medicine, biotechnology, and other fields that require a deep understanding of the molecular basis of life. The curriculum for the BS Biology major in Cell and Molecular Biology includes courses in Immunology, Bioinformatics, Molecular Genetics, Genomics and Proteomics, and Radiation Biology. Students in the BS Biology major in Cell and Molecular Biology also can participate in research projects, internships, and volunteer work. These experiences help students to gain hands-on experience in the biological sciences and to prepare for their future careers. Graduates of the BS Biology major in Cell and Molecular Biology are well-qualified for a variety of careers in the biological sciences and related fields. They may work as biomedical researchers, biotechnology scientists, pharmaceutical scientists, environmental scientists, food scientists, biomedical scientists, and science educators. 

        <br/>Graduates of BS Biology major in Cell and Molecular Biology have a wide range of job opportunities in the following fields:  
        <br/>1. Research and development (R&D)
        <br/>2. Biotechnology and pharmaceuticals
        <br/>3. Healthcare
        <br/>4. Education/ Academe
        <br/>5. Other fields: sales, marketing, or technical writing.  

        <br/>In addition to their technical skills, graduates of BS Biology major in Cell and Molecular Biology also have strong communication, critical thinking, and problem-solving skills. These skills are essential for success in any field, and they make graduates of BS Biology major in Cell and Molecular Biology valuable assets to any team.  

        <br/>A.3 BACHELOR OF SCIENCE IN BIOLOGY major in Ecology (BS BIO - Eco) (CHED CMO no. 49 s. 2017). <br/>This program offers students a solid grounding in the biological sciences, with a focus on the study of ecosystems and the interactions between organisms and their environment. The program prepares students for careers in research, education, and conservation. The curriculum for the BS Biology major in Ecology includes courses in Freshwater Ecology, Marine Ecology, Terrestrial Ecology, Population Ecology, and Biological Resource Management. Students in the BS Biology major in Ecology can gain hands-on experience in the biological sciences through research projects, internships, and volunteer work. These experiences help them to prepare for their future careers in a variety of fields related to ecology. They may work as ecologists, environmental scientists, conservation biologists, science educators, field researchers, park rangers, and natural resource managers.  

        <br/>Graduates of BS Biology major in Ecology have a wide range of job opportunities in the following fields:  
        <br/>1. Environmental consulting
        <br/>2. Environmental research
        <br/>3. Natural resource management
        <br/>4. Education/ Academe
        <br/>5. Other fields: sales, marketing, or technical writing.  

        <br/>In addition to their technical skills, graduates of BS Biology major in Ecology also have strong communication, critical thinking, and problem-solving skills. These skills are essential for success in any field, and they make graduates of BS Biology major in Ecology valuable assets to any team.  

        <br/>B. BACHELOR OF SCIENCE IN CHEMISTRY (CHED CMO No. 47, s 2017)  

        <br/>Bachelor of Science in Chemistry (BS Chem) is a four-year program that aims to produce graduates who comply with the current qualification requirements of professional chemists for local and overseas employment and entrepreneurship; and to prepare students for higher studies in chemistry and other fields.  

        <br/>The core disciplines of chemistry are inorganic, organic, analytical, physical chemistry and biochemistry. It is the foundation of science for many industrial and agricultural processes that produce useful products that contribute to the improvement of the quality of life. The graduates shall play a key role in the development of materials and processes to the production of sustainable renewable energy. It is indeed a science of numerous opportunities in the work field. 

        <br/>C. BACHELOR OF SCIENCE IN MATHEMATICS (CHED CMO No. 48, s 2017)

        <br/>The BS Mathematics (BS Math) program is a balance between a holistic general education program, and a substantial mathematics curriculum. The program aims to provide the students with a curriculum that represents the breadth and depth of mathematics, from classical to contemporary, and from theoretical to applied. It provides excellent preparation for advanced degrees in mathematics, physical sciences, economics, and industrial engineering, as well as graduate study in business, education, law, and medicine. The program also prepares students for postbaccalaureate positions in business, technology, industry, teaching, government, and finance.  

        <br/>The Bachelor of Science (BS) in Mathematics program is a dynamic journey through the world of numbers, patterns, and problem-solving. Rooted in a rich history that stretches back to ancient civilizations, mathematics has always been a driving force behind innovation and discovery. In its pursuit of excellence, the BS Mathematics program has consistently adapted and now embraces the latest changes mandated by the Commission on Higher Education (CHED) Memorandum Order (CMO) No, 48, series 2017.

        <br/>D. BACHELOR OF SCIENCE IN PSYCHOLOGY (BS Psych) (CHED CMO No. 34, s 2017)

        <br/>Psychology is the scientific study of behavior and mental processes. In general, the emphasis is on the individual person and how the person’s mental processes and behavior are affected by internal, relational, and social factors. Psychology as a discipline and professional practice, contributes to national development through basic and applied research and interventions aimed at solving problems and promoting optimal development and functioning at the individual, family, organizations/institutions, community and national levels. These undergraduate programs in psychology provide initial training for those interested in teaching, research and the practice of psychology. Furthermore, they provide preparation for graduate studies in psychology as well as further studies in other professions such as medicine, law, and business management.


        <br/>E. Graduate Programs  

        <br/>E.1.MASTER OF ARTS IN PSYCHOLOGY WITH SPECIALIZATION IN CLINICAL PSYCHOLOGY (MA Psych - CP) (CHED CMO No. 39, s 2010) 

        <br/>Rationale:  

        <br/>The students are exposed initially to the various areas of discipline.  These include personality and learning, developmental psychology, social psychology and the law.  Industrial psychology develops further the students ‘competencies needed in the industry.  

        <br/>E.2. MASTER OF ARTS IN PSYCHOLOGY WITH SPECIALIZATION IN CLINICAL PSYCHOLOGY  (MA Psych - IP) (CHED CMO No. 39, s 2010)

        <br/>Rationale:  

        <br/>The students are exposed initially to the various areas of discipline.  These include personality and learning, developmental psychology, social psychology and the law. Clinical psychology prepares psychologists for active practice in the clinical setting.  
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Awards and Recognition</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
          Licensure Examination (LE) Performance Records:  LE for Chemists (above National Passing, with Topnotchers), 
          <br/>LE for Chemical Technicians (above National Passing/ 100%, with Topnotchers), 
          <br/>LE for Psychometricians (above National Passing, included in the Top Performing College/ University, with Topnotchers), 
          <br/>LE for Psychologists (above National Passing).
      </Typography>
    </Box>
      <br/>
      <br/>
      <br/>
      </Paper>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CollegeScience;
