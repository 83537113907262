import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import { useRef } from 'react';

const ContactExecutive = () => {

  const contactExecutive = useRef(document.createElement("p"));

  const handleContact = () => {
    window.location.href="./Contact";
  };

  const handleContactCollege = () => {
    window.location.href="./ContactCollege";
  };

  const handleContactOffice = () => {
    window.location.href="./ContactOffice";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Contact Information</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
                {/* <MenuItem onClick={handleContact}>Contact PLM</MenuItem> */}
                <MenuItem onClick={handleContactCollege}>PLM Colleges</MenuItem>
                <MenuItem onClick={handleContactOffice}>PLM Offices</MenuItem>
                <MenuItem
                  onClick={() =>
                    contactExecutive.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  PLM Executive Offices
                </MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={contactExecutive} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Board of Regents</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: plmbor@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left"}}>
        <Typography>PLM office of the President</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: plm_op@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Office of the Executive vice President</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: oevp@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Office of the Vice President for Academic Support Units</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: vpasu@plm.edu.ph</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Office of University Legal Council</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: oulc@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Office of the Vice President for Administration</Typography>
        <Typography sx={{paddingRight: '5%'}}>Email: vpa@plm.edu.ph</Typography>
      </Box>
      <br />
      <Box sx={{ flexGrow: 0, justifyContent: "left" }}>
        <Typography>Office of the Vice President for Finance</Typography> 
        <Typography sx={{paddingRight: '5%'}}>Email: vpfm@plm.edu.ph</Typography>
      </Box>

      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default ContactExecutive;
