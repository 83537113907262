import { AppBar, Card, CardActionArea, CardContent, CardMedia, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import orgChart from './img/ourOrg.png';

const OfficeOUR = () => {

  const osds = useRef(document.createElement("p"));
  const handleOfficeAccounting = () => {
    window.location.href="./OfficeAccounting";
  };
  const handleOfficeBudget = () => {
    window.location.href="./OfficeBudget";
  };
  const handleOfficeCUES = () => {
    window.location.href="./OfficeCUES";
  };
  const handleOfficeGSO = () => {
    window.location.href="./OfficeGSO";
  };
  const handleOfficeAudit = () => {
    window.location.href="./OfficeAudit";
  };
  const handleOfficeGraduate = () => {
    window.location.href="./OfficeGraduate";
  };
  const handleOfficeOGTS = () => {
    window.location.href="./OfficeOGTS";
  };
  const handleOfficeNSTP = () => {
    window.location.href="./OfficeNSTP";
  };
  const handleOfficeOSDS = () => {
    window.location.href="./OfficeOSDS";
  };
  const handleOfficeULC = () => {
    window.location.href="./OfficeULC";
  };
  const handleOfficeOUR = () => {
    window.location.href="./OfficeOUR";
  };
  const handleOfficeUsec = () => {
    window.location.href="./OfficeUsec";
  };
  const handleOfficeVPRAE = () => {
    window.location.href="./OfficeVPRAE";
  };
  const handleOfficePFMO = () => {
    window.location.href="./OfficePFMO";
  };
  const handleOfficePMO = () => {
    window.location.href="./OfficePMO";
  };
  const handleOfficeLawCenter = () => {
    window.location.href="./OfficeLawCenter";
  };
  const handleOfficePO = () => {
    window.location.href="./OfficePO";
  };
  const handleOfficePSO = () => {
    window.location.href="./OfficePSO";
  };
  const handleOfficeRevenue = () => {
    window.location.href="./OfficeRevenue";
  };
  const handleOfficeUHS = () => {
    window.location.href="./OfficeUHS";
  };
  const handleOfficeLibrary = () => {
    window.location.href="./OfficeLibrary";
  };
  const handleOfficeURC = () => {
    window.location.href="./OfficeURC";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 60px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="left">Offices</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "25%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper  sx={{padding: '8px'}}>
              <MenuList>
                <MenuItem onClick={handleOfficeAccounting}>Accounting Office</MenuItem>
                <MenuItem onClick={handleOfficeBudget}>Budget Office</MenuItem>
                <MenuItem onClick={handleOfficeCUES}>Center of University Extension Services</MenuItem>
                <MenuItem onClick={handleOfficeGSO}>General Services Office</MenuItem>
                <MenuItem onClick={handleOfficeAudit}>Internal Audit Office</MenuItem>
                <MenuItem onClick={handleOfficeGraduate}>Office of Graduate/Professional Studies</MenuItem>
                <MenuItem onClick={handleOfficeOGTS}>Office of Guidance & Testing Services</MenuItem>
                <MenuItem onClick={handleOfficeNSTP}>Office of NSTP</MenuItem>
                <MenuItem onClick={handleOfficeOSDS}>Office of Student Development Services</MenuItem>
                <MenuItem onClick={handleOfficeULC}>Office of the University Legal Counsel</MenuItem>
                <MenuItem
                  onClick={() =>
                    osds.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Office of the University Registrar
                </MenuItem>

                <MenuItem onClick={handleOfficeUsec}>Office of the University Secretary</MenuItem>
                <MenuItem onClick={handleOfficeVPRAE}>Office of the VP for Research</MenuItem>
                <MenuItem onClick={handleOfficePFMO}>Physical Facilities Management Office</MenuItem>
                <MenuItem onClick={handleOfficePMO}>Planning And Management Office</MenuItem>
                <MenuItem onClick={handleOfficeLawCenter}>PLM Law Center</MenuItem>
                <MenuItem onClick={handleOfficePO}>Procurement Office</MenuItem>
                <MenuItem onClick={handleOfficePSO}>Property & Supplies Office</MenuItem>
                <MenuItem onClick={handleOfficeRevenue}>Revenue Generation Office</MenuItem>
                <MenuItem onClick={handleOfficeUHS}>University Health Services</MenuItem>
                <MenuItem onClick={handleOfficeLibrary}>University Library</MenuItem>
                <MenuItem onClick={handleOfficeURC}>University Research Center</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "75%", paddingLeft: '50px'}}>

      <Card>
      <CardActionArea>
        <CardMedia
          component="img"
          height="100%"
          width="100%"
          image={orgChart}
          alt="org chart"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Office of the University Registrar
          </Typography>
          <Typography variant="body2" color="text.secondary">
          <br/>The Pamantasan ng Lungsod ng Maynila was established on July 17, 1967 by virtue of RA No. 4196. Thus, the creation of the University automatically went with it the creation of the Office of the Registrar which takes charge of admissions, registration, scholastic records processing and management, and the commencement exercises of the students.
          <br/>The Office of the University Registrar (OUR) is an academic support unit of the University which administers operations in the areas of student academic records, graduation and other related services. It serves as the main frontline service unit of the University on curricular and academic matters. This Office issues documents such as Transcript of Records, Diploma, Certification of Grades, Course Description, Course Syllabus, Authentication/Verification and other academic records/documents.        
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

    <Card >
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Vision
          </Typography>
          <Typography variant="body2" color="text.secondary">
          To become a leader in academic record management and service delivery by leveraging cutting-edge technology, such as cloud-based systems and self-service portals. We aim to continuously improve our processes to enhance the academic experience for PLM students and graduates. By adopting best practices, exceeding service excellence benchmarks, and fostering a data-driven approach to success, we strive to set the standard for registrar services and contribute significantly to the academic achievement of our community.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

    <Card >
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Mission
          </Typography>
          <Typography variant="body2" color="text.secondary">
          The University Registrar's Office protects the accuracy, integrity, and security of student records and academic data. We offer efficient and effective academic services to students, professors, staff, and external organizations. By practicing the values of integrity, innovation, responsiveness, professionalism, and accountability, we support the academic progression of students and graduates, contributing to the university's mission and goals.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default OfficeOUR;
