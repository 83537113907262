import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CollegeLaw = () => {

  const law = useRef(document.createElement("p"));

  const handleCollegeCASBE = () => {
    window.location.href="./CollegeCASBE";
  };

  const handleCollegeCBA = () => {
    window.location.href="./CollegeCBA";
  };

  const handleCollegeCA = () => {
    window.location.href="./CollegeCA";
  };

  const handleCollegeCTHM = () => {
    window.location.href="./CollegeCTHM";
  };

  const handleCollegeCE = () => {
    window.location.href="./CollegeCE";
  };

  const handleCollegeCISTM = () => {
    window.location.href="./CollegeCISTM";
  };

  const handleCollegeCED = () => {
    window.location.href="./CollegeCED";
  };

  const handleCollegeCHASS = () => {
    window.location.href="./CollegeCHASS";
  };

  const handleCollegeNursing = () => {
    window.location.href="./CollegeNursing";
  };

  const handleCollegePT = () => {
    window.location.href="./CollegePT";
  };

  const handleCollegeScience = () => {
    window.location.href="./CollegeScience";
  };

  const handleCollegeMedicine = () => {
    window.location.href="./CollegeMedicine";
  };

  const handleCollegeBS = () => {
    window.location.href="./CollegeBS";
  };

  const handleCollegeGLaw = () => {
    window.location.href="./CollegeGLaw";
  };

  const handleCollegePublicHealth = () => {
    window.location.href="./CollegePublicHealth";
  };

  const handleCollegeGovernment = () => {
    window.location.href="./CollegeGovernment";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"  alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Colleges</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
              <MenuItem onClick={handleCollegeCASBE}>Architecture</MenuItem>
              <MenuItem onClick={handleCollegeCBA}>Business Administration</MenuItem>
              <MenuItem onClick={handleCollegeCA}>Accountancy</MenuItem>
              <MenuItem onClick={handleCollegeCTHM}>Tourism & Hotel Management</MenuItem>
              <MenuItem onClick={handleCollegeCED}>Education</MenuItem>
              <MenuItem onClick={handleCollegeCE}>Engineering</MenuItem>
              <MenuItem onClick={handleCollegeCISTM}>Information System</MenuItem>
              <MenuItem onClick={handleCollegeCHASS}>Humanities, Arts and Social Science</MenuItem>
              <MenuItem onClick={handleCollegeNursing}>Nursing</MenuItem>
              <MenuItem onClick={handleCollegePT}>Physical Therapy</MenuItem>
              <MenuItem onClick={handleCollegeScience}>Science</MenuItem>
              <MenuItem
                  onClick={() =>
                    law.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Law
                </MenuItem>
                <MenuItem onClick={handleCollegeMedicine}>Medicine</MenuItem>
                <MenuItem onClick={handleCollegeBS}>Business School</MenuItem>
                <MenuItem onClick={handleCollegeGLaw}>Graduate School of Law</MenuItem>
                <MenuItem onClick={handleCollegePublicHealth}>Public Health</MenuItem>
                <MenuItem onClick={handleCollegeGovernment}>Government</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={law} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Paper elevation={2} sx={{padding: '16px'}}>
          
      <Box sx={{ flexGrow: 0, justifyContent: "left", backgroundColor: "#A31920"}}>
        <Typography variant="h5" color="white">College of Law</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The College of Law formally opened its door in the School Year 1989-1990. Board Resolution No. 1329 dated 18 April 1989 established the College of Law and approved a curriculum for the first year level. On 02 April 1990, the Board approved a curriculum for the second to fourth year levels based on DECS Order No. 27, s. 1989 (Standard Law Curriculum). The creation of the College of Law was a testament to the social justice orientation of PLM. 
        <br/>The College adopted and implemented its new curriculum, which incorporates the latest developments and trends in the field of law.
        </Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">History</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The College of Law formally opened its door in the School Year 1989-1990. Board Resolution No. 1329 dated 18 April 1989 established the College of Law and approved a curriculum for the first year level. On 02 April 1990, the Board approved a curriculum for the second to fourth year levels based on DECS Order No. 27, s. 1989 (Standard Law Curriculum). The creation of the College of Law was a testament to the social justice orientation of PLM. 
        </Typography>
      </Box>      
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Vision</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The general law program hopes to provide men and women with high legal education at the least possible cost, preparing them for the practice of law: that is geared towards public service and social justice. Towards this end, the law program is designed:
<br/>1.  To train the students to have a clear grasp of the law.
<br/>2.  To develop the student’s ability to think logically, methodically, and to express himself clearly and forcefully.
<br/>3.  To train the students for leadership in various spheres of public service.
<br/>4.  To contribute to the development of Philippine jurisprudence and legal literature.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Mission</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Guided by the Life Purpose and inspired by the Vision, we commit ourselves to wit:
<br/>1.     To motivate and develop competent, productive and ethical professionals, leaders, and citizens of/in Manila.
<br/>2.   To conduct and sustain relevant, innovative researches and extension services for the enrichment of scholarship and community development.
<br/>3.   To develop and maintain a corps of competent, committed and ethical administrators, faculty and service personnel for the efficient and effective delivery of quality academic and administrative services.
<br/>4.     To provide, improve and maintain adequate and functional infrastructures supportive of and conducive to quality education.
<br/>5.      To promote and maintain mutually beneficial linkages and networks with institutions and agencies at the local and international levels.
<br/>6.       To engender and enhance the goodwill and support of alumni, friends, and other publics for a viable and sustainable university.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Objectives</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The general law program hopes to provide men and women with high legal education at the least possible cost, preparing them for the practice of law: that is geared towards public service and social justice. Towards this end, the law program is designed:
<br/>1.      To train the students to have a clear grasp of the law.
<br/>2.   To develop the student’s ability to think logically, methodically, and to express himself clearly and forcefully.
<br/>3.      To train the students for leadership in various spheres of public service.
<br/>4.    To contribute to the development of Philippine jurisprudence and legal literature.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Public Offering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
          Juris Doctor
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Awards and Recognition</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
              Examinees&nbsp;&nbsp;&nbsp;&nbsp;PLM Passing Percentage&nbsp;&nbsp;&nbsp;&nbsp;PLM Passing Percentage&nbsp;&nbsp;&nbsp;&nbsp;Nat'l Passing Percentage
              <br/>2013&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;38.46%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;41.67%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;22.18%
              <br/>2014&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;46.15%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;32.00%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;18.82%
              <br/>2015&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;61.54%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;44.44%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;26.21%
              <br/>2016&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;100.00%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;96.43%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;59.00%
              <br/>2017&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;73.33%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;54.55%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;25.55%
              <br/>2018&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;54.55%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;32.00%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;18.82%
              <br/>2019&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;60.00%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;61.29%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;27.36%
              <br/>2020 -2021&nbsp;&nbsp;100.00%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;95.00%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;72.28%
              <br/>2022&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;85.00%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;81.82%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;43.47%
              <br/>2023&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;74.29%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;67.50%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;36.77%
      </Typography>
    </Box>

      <br/>
      <br/>
      <br/>
      </Paper>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CollegeLaw;
