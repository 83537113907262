import { AppBar, Button, MenuItem, MenuList, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import board1 from './img/administration/Atty. Edward S. Serapio, Chairman.png';
import board2 from './img/administration/Atty. Domingo Y. Reyes Jr.png';
import board5 from './img/administration/Wilma V. Galvante, Member.png';
import board4 from './img/administration/Joseph Berlin P. Juanzon, Ph.D.png';
import board6 from './img/administration/Neri S. Pescadera, Ph.D.png';
import board3 from './img/administration/Rita E. Riddle, Ph.D., Superintendent, DCS-Manila and Ex-officio Member.png';
import board7 from './img/administration/Atty. Marisol DL Anenias.png';
import board8 from './img/administration/Atty. Elvira Cruz.png';
import { useRef } from 'react';
import EmailIcon from '@mui/icons-material/EmailRounded';

const Administration = () => {
  const board = useRef(document.createElement("p"));
  const composition = useRef(document.createElement("p"));
  const term = useRef(document.createElement("p"));

  const handlePresident = () => {
    window.location.href="./President";
  };

  const handleVPresident = () => {
    window.location.href="./VPresident";
  };

  const handleDirectorChief = () => {
    window.location.href="./DirectorChief";
  };

  const handleDean = () => {
    window.location.href="./Dean";
  };

  const handleOrgChart = () => {
    window.location.href="./OrgChart";
  };

  const handleSupportStaff = () => {
    window.location.href="./SupportStaff";
  };

  const handlePastPresident = () => {
    window.location.href="./PastPresident";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Administration</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "2vw"}}>
      <AppBar position="relative" style={{ background: 'white', height: "5vw", marginTop: "1vw"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem
          onClick={() =>
            board.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          Board of Regents
        </MenuItem>
        <MenuItem onClick={handlePresident}>The President</MenuItem>
        <MenuItem onClick={handleVPresident}>Management Committee</MenuItem>
        <MenuItem onClick={handleDirectorChief}>Directors and Chiefs</MenuItem>
        <MenuItem onClick={handleDean}>Deans</MenuItem>
        <MenuItem onClick={handleOrgChart}>Organizational Chart</MenuItem>
        <MenuItem onClick={handleSupportStaff}>Presidential Support Staff</MenuItem>
        <MenuItem onClick={handlePastPresident}>Past University Presidents</MenuItem>
        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '2vw', display: { xs: 'none', md: 'flex' } }} />


      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">The Board of Regents</Typography>
      </Box>
      <br/>
      
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">The Board of Regents, the highest policy-making body of the university, sets and steers the direction of the university to fulfill its vision and mission.</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">The Board presents a stellar lineup comprised of esteemed professionals in their respective fields. They bring with them expertise that would help PLM realize its goals.</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">The Chairperson of the Board and the University President are elected from among the regents themselves. The Chairperson presides over all the meetings of the Board, while the University President serves as its ex-officio Vice-Chair.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '2vw', display: { xs: 'none', md: 'flex' } }} />

      <Box  sx={{ flexGrow: 0, justifyContent: "center", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={board1} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "center", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Atty. Edward S. Serapio</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "center", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">esserapio@plm.edu.ph</Typography></Button></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "center", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Chairman</Typography></Box>
      </Box>

      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '2vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={board2} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={board3} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={board4} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={board5} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Atty. Domingo Y. Reyes Jr.</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Dr. Rita E. Riddle</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Dr. Joseph Berlin P. Juanzon</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Ms. Wilma V. Galvante</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">dyreyes@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">reriddle@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">jbpjuanzon@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">wvgalvante@plm.edu.ph</Typography></Button></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">University President and Vice-Chairman</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Ex-Officio Member and Superintendent of the Division of City Schools - Manila</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Member and Executive Director of the Office for Graduate and Professional Studies</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Member</Typography></Box>

      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '2vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>

        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={board6} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={board7} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={board8} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Dr. Neri S. Pescadera</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Atty. Marisol DL Anenias</Typography></Box>
        <Box width="25%" style={{  paddingLeft: "1vw"}}><Typography color="primary" variant="body2" fontSize= "1vw">Atty Elvira Cruz</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">nspescadera@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">mdlanenias@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0vw"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "1vw">ecruz@plm.edu.ph</Typography></Button></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Member</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">Member</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "1vw">University and Board Secretary</Typography></Box>
      </Box>

      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '5px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Composition</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">The Board of Regents is a collegial body composed of seven (7) highly distinguished members, six (6) of whom are appointed by the Mayor of the city of Manila, with the consent of the then-Municipal Board, which is now the City Council of Manila. Of the six members appointed by the Mayor, the PLM Charter prescribes that one comes from the faculty, another is a distinguished alumnus, and one of the others from the field of education. The university regents are chosen on the basis of their professional competence. They are men of high and eminent integrity and distinguished professionals among the citizenry. The Division of City Schools (DCS) superintendent of Manila sits as an ex-officio member of the Board of Regents and becomes the 7th member of the Board.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '5px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Term of Office</Typography>
      </Box>

      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">Confirmed regential appointees automatically serve for a fixed term of six (6) years or until their successors shall have been appointed and qualified. They may therefore serve for more than 6 years on a hold-over capacity, provided no replacement shall have been qualified as of yet.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">In case an incumbent regent cannot finish his term of six years by reason of resignation, death or other incapacities, the vacancy shall be filled by an appointment by the Mayor of the City of Manila, with the consent of the City Council, and the appointee shall hold office for the unexpired portion of the term, instead of the fixed term of six (6) years.</Typography>
      </Box>
      <br/>

      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default Administration;
