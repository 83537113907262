import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CollegeMedicine = () => {

  const medicine = useRef(document.createElement("p"));

  const handleCollegeCASBE = () => {
    window.location.href="./CollegeCASBE";
  };

  const handleCollegeCBA = () => {
    window.location.href="./CollegeCBA";
  };

  const handleCollegeCA = () => {
    window.location.href="./CollegeCA";
  };

  const handleCollegeCTHM = () => {
    window.location.href="./CollegeCTHM";
  };

  const handleCollegeCE = () => {
    window.location.href="./CollegeCE";
  };

  const handleCollegeCISTM = () => {
    window.location.href="./CollegeCISTM";
  };

  const handleCollegeCED = () => {
    window.location.href="./CollegeCED";
  };

  const handleCollegeCHASS = () => {
    window.location.href="./CollegeCHASS";
  };

  const handleCollegeNursing = () => {
    window.location.href="./CollegeNursing";
  };

  const handleCollegePT = () => {
    window.location.href="./CollegePT";
  };

  const handleCollegeScience = () => {
    window.location.href="./CollegeScience";
  };

  const handleCollegeLaw = () => {
    window.location.href="./CollegeLaw";
  };

  const handleCollegeBS = () => {
    window.location.href="./CollegeBS";
  };

  const handleCollegeGLaw = () => {
    window.location.href="./CollegeGLaw";
  };

  const handleCollegePublicHealth = () => {
    window.location.href="./CollegePublicHealth";
  };

  const handleCollegeGovernment = () => {
    window.location.href="./CollegeGovernment";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"  alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Colleges</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
              <MenuItem onClick={handleCollegeCASBE}>Architecture</MenuItem>
              <MenuItem onClick={handleCollegeCBA}>Business Administration</MenuItem>
              <MenuItem onClick={handleCollegeCA}>Accountancy</MenuItem>
              <MenuItem onClick={handleCollegeCTHM}>Tourism & Hotel Management</MenuItem>
              <MenuItem onClick={handleCollegeCED}>Education</MenuItem>
              <MenuItem onClick={handleCollegeCE}>Engineering</MenuItem>
              <MenuItem onClick={handleCollegeCISTM}>Information System</MenuItem>
              <MenuItem onClick={handleCollegeCHASS}>Humanities, Arts and Social Science</MenuItem>
              <MenuItem onClick={handleCollegeNursing}>Nursing</MenuItem>
              <MenuItem onClick={handleCollegePT}>Physical Therapy</MenuItem>
              <MenuItem onClick={handleCollegeScience}>Science</MenuItem>
              <MenuItem onClick={handleCollegeLaw}>Law</MenuItem>
              <MenuItem
                  onClick={() =>
                    medicine.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Medicine
                </MenuItem>
                <MenuItem onClick={handleCollegeBS}>Business School</MenuItem>
                <MenuItem onClick={handleCollegeGLaw}>Graduate School of Law</MenuItem>
                <MenuItem onClick={handleCollegePublicHealth}>Public Health</MenuItem>
                <MenuItem onClick={handleCollegeGovernment}>Government</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={medicine} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Paper elevation={2} sx={{padding: '16px'}}>
          
      <Box sx={{ flexGrow: 0, justifyContent: "left", backgroundColor: "#A31920"}}>
        <Typography variant="h5" color="white">College of Medicine</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
          The Pamantasan ng Lungsod ng Maynila (PLM) College of Medicine, established in 1983 and funded by the local government of Manila, is the first local institution in the Philippines offering a Doctor of Medicine degree. It provides opportunities for financially disadvantaged but academically deserving students. Renowned for developing competent and compassionate medical practitioners, the College addresses the healthcare needs of Manila, the nation, and the world. Over three decades, it has gained respect as a premier medical school. The College remains committed to academic excellence, further training, research, and community service.
        </Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">History</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The Pamantasan ng Lungsod ng Maynila (PLM) College of Medicine was established in June 1983 by virtue of Resolution No. 806, series of 1980, passed on December 15, 1980, by the PLM Board of Regents. From its inception, the College of Medicine aimed to offer a unique, community-based medical education designed to address the healthcare needs of Manila's residents, particularly those in underserved barangays, by producing primary care physicians.
        <br/>In June 1983, the College of Medicine formally commenced operations with an inaugural class of 48 medical scholars, all from Manila. Three years later, as part of its commitment to quality medical education, the college extended its scholarship program to qualified non-Manila residents, offering substantial financial support. Notably, the College of Medicine is entirely funded by the local government of Manila and holds the distinction of being the first local educational institution in the country to offer a Doctor of Medicine degree.
        <br/>Over the years, the PLM College of Medicine has earned a reputation as one of the Philippines' premier medical schools. It consistently ranks among the top five medical schools in the Physician Licensure Examinations conducted by the Professional Regulations Commission (PRC). Furthermore, in recognition of its excellence, the college has received Level III accreditation from the Philippine Accrediting Association of Schools, Colleges, and Universities (PAASCU).
        <br/>Our institution takes pride in its graduates, many of whom have been locally and nationally recognized for their outstanding performance and service. Even those who have not received formal accolades continue to make significant contributions as primary care physicians, medical specialists, educators, researchers, and public health advocates. They are dedicated to serving the healthcare needs of Manila, the nation, and the global community. These graduates remain steadfast in their commitment to enhancing overall health and well-being in society, making them the invaluable "Golden Harvests" of the PLM College of Medicine.
        </Typography>
      </Box>      
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Vision</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Anchored upon our University’s Vision, Mission and Objectives, we at the College of Medicine, aspire to be:
        <br/>An excellent center committed to community-oriented medical education, training, research and service.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Mission</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        In pursuit of our vision we commit ourselves to be:
        <br/>A professional school for the education and training of medical professionals who are highly competent, globally competitive, ethical, caring and dedicated to serve the health needs of the City of Manila, the nation and the world.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Objectives</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Guided by this mission, the PLM College of Medicine seeks:
<br/>•        To produce excellent community-oriented medical practitioners who value human life and promote health and wellness.
<br/>•        To nurture the ideals of nationalism and the values of social sensitivity and responsibility, holistic care with compassion and dedication to the medical profession.
<br/>•        To create a culture of academic excellence and social responsibility among the administrators, faculty and staff.
<br/>•        To provide quality and relevant researches.
<br/>•         To strengthen local, national and international linkages that will contribute to quality medical education.
<br/>•        To provide appropriate and adequate infrastructure in support of the community-oriented medical education.
<br/>•        To involve the alumni association in enhancing and sustaining community-oriented medical programs.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Public Offering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Doctor of Medicine
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Awards and Recognition</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Consistently in the top 2 performing medical schools in the Physician Licensure Examination for the last 3 years 
      </Typography>
    </Box>

      <br/>
      <br/>
      <br/>
      </Paper>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CollegeMedicine;
