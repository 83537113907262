import {Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import board_mechanical2022 from './img/board_mechanical2022.jpg';
import board_archi2020 from './img/board_archi2020.jpg';
import board_civil2022 from './img/board_civil2022.jpg';
import board_bar20202021 from './img/board_bar20202021.jpg'
import board_mechanicalfeb2022 from './img/board_mechanicalfeb2022.jpg';
import board_archi2022 from './img/board_archi2022.jpg';
import board_cpa2021 from './img/board_cpa2021.jpg';
import board_nursing2021 from './img/board_nursing2021.jpg';
import board_physician2021 from './img/board_physician2021.jpg';
import board_physiciantop2021 from './img/board_physiciantop2021.jpg';
import board_chemeng2021 from './img/board_chemeng2021.jpg';
import board_physicianmarch2021 from './img/board_physicianmarch2021.jpg';
import board_bar2019 from './img/board_bar2019.jpg'
import board_archijan2020 from './img/board_archijan2020.jpg';
import board_nursing2019 from './img/board_nursing2019.jpg';
import board_chemeng2019 from './img/board_chemeng2019.jpg';
import board_civil2019 from './img/board_civil2019.jpg';
import board_psychometrician2019 from './img/board_psychometrician2019.jpg';
import board_psychometricianoct2019 from './img/board_psychometricianoct2019.jpg';
import board_ece2019 from './img/board_ece2019.jpg';
import board_archi2019 from './img/board_archi2019.png';
import board_physician2019100 from './img/board_physician2019100.jpg';
import board_physician2019 from './img/board_physician2019.jpg';
import board_mechanical2018 from './img/board_mechanical2018.jpg';
import board_ee2018 from './img/board_ee2018.jpg';

const PrideHall = () => {
  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Pride Hall</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Bar Exam - College of Law</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={board_bar20202021} width="25%" alt="" />
        <img src={board_bar2019} width="25%" alt="" />
        <Typography variant="h6" width="25%"></Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" width="25%">PLM clinches 100% passing rate in 2020-2021 Bar exams</Typography>
        <Typography variant="h6" width="25%">Congratulations to 19 new PLM lawyers</Typography>
        <Typography variant="h6" width="25%">Legal Education Board cites PLM for ranking 1st in Bar</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Board Exam - Certified Public Accountant Licensure Examination</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={board_cpa2021} width="25%" alt="" />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" width="25%">PLM congratulates 8 new certified public accountants</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Board Exam - Architecture</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={board_archi2022} width="25%" alt="" />
        <img src={board_archi2020} width="25%" alt="" />
        <img src={board_archijan2020} width="25%" alt="" />
        <img src={board_archi2019} width="25%" alt="" />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" width="25%">PLM alumna ranks 10th in Architecture board exams</Typography>
        <Typography variant="h6" width="25%">PLM grad ranks 6th in architecture board exam</Typography>
        <Typography variant="h6" width="25%">PLM scores 90.48% in Architecture board exam</Typography>
        <Typography variant="h6" width="25%">PLM graduate is architect board topnotcher</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Board Exam - Chemical Engineering</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={board_chemeng2021} width="25%" alt="" />
        <img src={board_chemeng2019} width="25%" alt="" />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" width="25%">PLM marks 87.5% passing rate in chemical engineer licensure exam</Typography>
        <Typography variant="h6" width="25%">PLM gets 100% passing rate in Chemical Eng'g board exam</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Board Exam - Civil Engineering</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={board_civil2022} width="25%" alt="" />
        <img src={board_civil2019} width="25%" alt="" />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" width="25%">PLM congratulates 22 new civil engineers, 6 electrical engineers</Typography>
        <Typography variant="h6" width="25%">PLM gets 93% passing rate in Civil Eng'g exam</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Board Exam - Electronic and Communication Engineering</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={board_ece2019} width="25%" alt="" />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" width="25%">PLM gets 100% passing rate in ECE, ET 2019 board exam</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Board Exam - Electrical Engineering</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={board_ee2018} width="25%" alt="" />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" width="25%">PLM gets 100% in the Electrical Engineering board</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Board Exam - Mechanical Engineering</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={board_mechanical2022} width="25%" alt="" />
        <img src={board_mechanicalfeb2022} width="25%" alt="" />
        <img src={board_mechanical2018} width="25%" alt="" />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" width="25%">PLM logs 61.54% passing rate in Mechanical Engineering board exam</Typography>
        <Typography variant="h6" width="25%">PLM congratulates 6 newly licensed mechanical engineers</Typography>
        <Typography variant="h6" width="25%">PLM maintains a 100% passing victory in the Mechanical Engineering board</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Board Exam - Nursing</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={board_nursing2021} width="25%" alt="" />
        <img src={board_nursing2019} width="25%" alt="" />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" width="25%">3 PLM Nursing grads top November 2021 licensure exam, 100% passing rate achieved</Typography>
        <Typography variant="h6" width="25%">PLM gets 100% passing rate, 3 topnotchers in nursing board exam</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Board Exam - Physician</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={board_physician2021} width="25%" alt="" />
        <img src={board_physiciantop2021} width="25%" alt="" />
        <img src={board_physicianmarch2021} width="25%" alt="" />
        <img src={board_physician2019100} width="25%" alt="" />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" width="25%">PLM logged the highest passing rate among all medical schools with a 98.06% passing rate</Typography>
        <Typography variant="h6" width="25%">PLM tops Physician Licensure Exam, alumnus lands 5th place</Typography>
        <Typography variant="h6" width="25%">PLM congratulates 15 new medical doctors</Typography>
        <Typography variant="h6" width="25%">PLM gets 100% passing rate, holds recognition rite for MD board passers</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={board_physician2019} width="25%" alt="" />
        <Typography variant="h6" width="25%"></Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" width="25%">August 2019 Physical Therapist Licensure Exam</Typography>
        <Typography variant="h6" width="25%">2018 September Physician Licensure Examination</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Board Exam - Psychometrician</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={board_psychometrician2019} width="25%" alt="" />
        <img src={board_psychometricianoct2019} width="25%" alt="" />
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" width="25%">PLM graduate is Top 5 in Psychometrician board exam</Typography>
        <Typography variant="h6" width="25%">PLM named Top 2 school in 2019 Psychometrician exam</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">PLM as Model</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" width="75%"></Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" width="75%">CHED cites PLM as model for local colleges and universities</Typography>
      </Box>
      <br/>

      <br/>
      <br/>
      <br/>
      <Footer/>
    </div>
  );
}

export default PrideHall;
