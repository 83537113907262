import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CollegeGLaw = () => {

  const glaw = useRef(document.createElement("p"));

  const handleCollegeCASBE = () => {
    window.location.href="./CollegeCASBE";
  };

  const handleCollegeCBA = () => {
    window.location.href="./CollegeCBA";
  };

  const handleCollegeCA = () => {
    window.location.href="./CollegeCA";
  };

  const handleCollegeCTHM = () => {
    window.location.href="./CollegeCTHM";
  };

  const handleCollegeCE = () => {
    window.location.href="./CollegeCE";
  };

  const handleCollegeCISTM = () => {
    window.location.href="./CollegeCISTM";
  };

  const handleCollegeCED = () => {
    window.location.href="./CollegeCED";
  };

  const handleCollegeCHASS = () => {
    window.location.href="./CollegeCHASS";
  };

  const handleCollegeNursing = () => {
    window.location.href="./CollegeNursing";
  };

  const handleCollegePT = () => {
    window.location.href="./CollegePT";
  };

  const handleCollegeScience = () => {
    window.location.href="./CollegeScience";
  };

  const handleCollegeLaw = () => {
    window.location.href="./CollegeLaw";
  };

  const handleCollegeMedicine = () => {
    window.location.href="./CollegeMedicine";
  };

  const handleCollegeBS = () => {
    window.location.href="./CollegeBS";
  };

  const handleCollegePublicHealth = () => {
    window.location.href="./CollegePublicHealth";
  };

  const handleCollegeGovernment = () => {
    window.location.href="./CollegeGovernment";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Colleges</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
              <MenuItem onClick={handleCollegeCASBE}>Architecture</MenuItem>
              <MenuItem onClick={handleCollegeCBA}>Business Administration</MenuItem>
              <MenuItem onClick={handleCollegeCA}>Accountancy</MenuItem>
              <MenuItem onClick={handleCollegeCTHM}>Tourism & Hotel Management</MenuItem>
              <MenuItem onClick={handleCollegeCED}>Education</MenuItem>
              <MenuItem onClick={handleCollegeCE}>Engineering</MenuItem>
              <MenuItem onClick={handleCollegeCISTM}>Information System</MenuItem>
              <MenuItem onClick={handleCollegeCHASS}>Humanities, Arts and Social Science</MenuItem>
              <MenuItem onClick={handleCollegeNursing}>Nursing</MenuItem>
              <MenuItem onClick={handleCollegePT}>Physical Therapy</MenuItem>
              <MenuItem onClick={handleCollegeScience}>Science</MenuItem>
              <MenuItem onClick={handleCollegeLaw}>Law</MenuItem>
              <MenuItem onClick={handleCollegeMedicine}>Medicine</MenuItem>
              <MenuItem onClick={handleCollegeBS}>Business School</MenuItem>
              <MenuItem
                  onClick={() =>
                    glaw.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Graduate School of Law
                </MenuItem>
                <MenuItem onClick={handleCollegePublicHealth}>Public Health</MenuItem>
                <MenuItem onClick={handleCollegeGovernment}>Government</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={glaw} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Paper elevation={2} sx={{padding: '16px'}}>
          
      <Box sx={{ flexGrow: 0, justifyContent: "left", backgroundColor: "#A31920"}}>
        <Typography variant="h5" color="white">Graduate School of Law</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The PLM's Graduate School of Law provides legal education to lawyers and non-lawyers who have obtained their first law degree in any college or university in the country offering either a Bachelor of Laws or Juris Doctor program. 
<br/>It aims to attract in its fold lawyers, including judges, prosecutors, practitioners, and those employed in the government and private service.
<br/>The GSL boasts of its professors who are experts in their respective areas of law, intellectually vigorous, engaged, and committed to quality teaching and high levels of service to students. They come to class with extensive experience having practiced law for the public sector, businesses, private firms, and the Judiciary.

        </Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">History</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        "On July 7, 2004, the Graduate School of Law was formally launched as the second graduate school of law in the Philippines. On July 29, 2004, the Board of Regents by virtue of BOR Resolution No. 2686 approved the offering of the graduate program of Master of Laws.
        <br/>The Master of Laws Program (LLM) is offered by the PLM Graduate School of Law as a two-year program, trimestral terms, with strong focus on research that culminates in a thesis. Courses are delivered entirely in English using a multidisciplinary approach by distinguished Jurist and Law Practitioners."
        </Typography>
      </Box>      
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Vision</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The PLM Graduate School of Law envisions itself as the reflection of an ideal institution of intellectual and highly principled lawyers, with advanced excellent legal training, who will rise to higher callings with commitments to be of service to our country and people."
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Mission</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        "The PLM Graduate School of Law will stress the noble mission of lawyers and judges as well. It will likewise assist in raising the high standards of the legal profession needed in the effective and efficient dispensation of justice for the good of the country, and in contributing meaningful efforts in the pursuit of global peace, and cooperation through law and universal understanding. The PLM Graduate School of Law, inspired by the legacy left by the late President Diosdado M. Macapagal, whose brilliance, integrity and devotion to public duty and service, his concern for the common people, specially the poor and under privileged desirous of achieving higher learning in the midst of economic hardship---envisions to promote and develop educational advancement, leadership and sense of patriotism among members of the Philippine Bar."
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Objectives</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The Graduate School of Law GSL committed to the mission and vision of the University guided by the values of academic excellence, integrity and social responsibility, and by the principles of Karunungan, Kaunlaran and Kadakilaan. Implicit from its noble mission and vision, the PLM Graduate School of law aims – 
<br/>1. To develop intellectual expertise in law and jurisprudence among lawyers;
<br/>2. To assist in improving public service by stressing the lawyers' mission of upholding justice and truth;
<br/>3. To elevate the standards of the legal profession; and
<br/>4. To enhance the administration of justice for the welfare of the Filipino people, especially the marginalized.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Public Offering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
          The PLM Graduate School of Law Master of Laws (LL.M.) program is two-year degree program which culminates in a thesis.
          <br/>Its two-year LL.M. (Master of Laws) program provides students, who already have legal training and experience, with broad latitude to design a course of study that will give them an expanded understanding of law and jurisprudence.
          <br/>Its curriculum combines the best of traditional courses, as well as legal developments, effective advocacy before the appellate courts, thesis writing, and other allied subjects.
          <br/>Significantly, the concept of its curriculum also prepares its graduates for the professional challenges globalization entails and thus, will enable them to approach the law in a more universally aware manner.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Awards and Recognition</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        GSL's LL.M. students include private practitioners,  government employees from various agencies, deans, law professors, judges (both retired and incumbent), justices of appellate court, court attorneys, businessmen and women. The diversity of the students in the LL.M. Program contributes significantly to the educational experience of the entire LL.M. community -- 
        <br/>RTC Judges
        <br/>MTC Judges
        <br/>Branch Clerks of Court
        <br/>Court Attorneys in the Supreme Court
        <br/>Court Attorneys in the Court of Appeals
        <br/>Justice of the Court of Appeals
        <br/>Members of the academe as law deans and law professors
        <br/>Active private practitioners
        <br/>National Prosecution Service as Prosecutors and PAO Lawyers
        <br/>Attorneys in the different government agencies, i.e. National Bureau of Investigation, City Council of Manila, Bangko Sentral ng Pilipinas, Department of Trade and Industry, Department of Public Works and Highways, Social Security System, and Securities and Exchange Commission, among others.
        </Typography>
    </Box>

      <br/>
      <br/>
      <br/>
      </Paper>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CollegeGLaw;
