import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import { useRef } from 'react';

const UniversityProfile = () => {
  const vision = useRef(document.createElement("p"));
  const mission = useRef(document.createElement("p"));
  const core = useRef(document.createElement("p"));

  const handleGoals = () => {
    window.location.href="./Goals";
  };

  const handleSeal = () => {
    window.location.href="./Seal";
  };

  const handleHistory = () => {
    window.location.href="./History";
  };

  const handleHymn = () => {
    window.location.href="./Hymn";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography fontSize='4vw' alignContent="center">Vision / Mission</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "2vw"}}>
      <AppBar position="relative"style={{ background: 'white', height: "5vw", marginTop: "1vw"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
          <MenuItem
            onClick={() =>
              vision.current.scrollIntoView({
                behavior: "smooth",
                block: "start"       
              })
            }
          >
            <Typography fontSize='1vw'>Vision / Mission</Typography>
          </MenuItem>
          <MenuItem onClick={handleGoals}><Typography fontSize='1vw'>Goals / Key Result Areas</Typography></MenuItem>
          <MenuItem onClick={handleSeal}><Typography fontSize='1vw'>Seal and Symbols</Typography></MenuItem>
          <MenuItem onClick={handleHistory}><Typography fontSize='1vw'>PLM History</Typography></MenuItem>
          <MenuItem onClick={handleHymn}><Typography fontSize='1vw'>University Hymn</Typography></MenuItem>
        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box ref={vision} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography fontSize='2vw'>Vision</Typography>
      </Box>

      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography fontSize='1.5vw'>The Pamantasan ng Lungsod ng Maynila shall be the premier people’s university pursuing public interest and national development.</Typography>
      </Box>
    

      <Box ref={mission} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography fontSize='2vw'>Mission</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography fontSize='1.5vw'>The Pamantasan ng Lungsod ng Maynila shall form critical-minded and service-oriented leaders and innovators in various disciplines through accessible and relevant quality education, transformative institutional research and extension services, and key linkages.</Typography>
      </Box>
      <br/>

      <Box ref={core} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '24px', display: { xs: 'none', md: 'flex' } }}>
        <Typography fontSize='2vw'>Core Values</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography fontSize='1.5vw'>The Pamantasan ng Lungsod ng Maynila shall be guided by the values of academic excellence, integrity and social responsibility, and by the principles of Karunungan, Kaunlaran and Kadakilaan.</Typography>
      </Box>
      <br/>


      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default UniversityProfile;
