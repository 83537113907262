import { Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import smlogo from './img/sm.png';
import simpliciologo from './img/simplicio.png';
import securitybanklogo from './img/securitybank.png';
import reallifelogo from './img/RealLifeLogo.png';
import pwclogo from './img/pwc.jpg';
import plmscholarslogo from './img/plmscholars.jpg';
import picpalogo from './img/picpa.jpg';
import mptclogo from './img/mptc.png';
import megaworldlogo from './img/megaworld.png';
import lccklogo from './img/lcck.png';
import lingaplogo from './img/lingap.jpg';
import gokongweilogo from './img/gokongwei.png';

// import InnerBanner from './img/aboutplm.jpg';

const Scholarship = () => {
  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "left", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Scholarships and Financial Aid</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">PLM partners with foundations and generous individuals to provide poor but deserving students the fighting chance that they deserve. Depending on the partners, scholars receive monthly stipends, book allowances, and even a year-end bonus.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="center">AY 2022-2023 Scholarship partners</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">For the Academic Year 2022-2023, 24 scholarship partners provide PLM students with not only financial assistance but also training and opportunities for exposure. Out of the 24, 21 are group sponsors and three are individual providers.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="center">The following are the group scholarship providers:</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={smlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
       <Typography variant="h6" alignContent="center">AY Foundation</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={smlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
       <Typography variant="h6" alignContent="center">AMY Foundation</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={smlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
        <Typography variant="h6" alignContent="center">Andres P. Tamayo Sr., Foundation, Inc. </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={smlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
        <Typography variant="h6" alignContent="center">Buddhist Compassion Relief Tzu Chi Foundation Philippines, Inc.  </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={smlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
       <Typography variant="h6" alignContent="center">Cebuana Lhuillier Foundation, Inc.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={smlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
       <Typography variant="h6" alignContent="center">Charity First Foundation, Inc.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
      <img src={smlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
       <Typography variant="h6" alignContent="center">Chinese Filipino Business Club, Inc.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
      <img src={smlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
       <Typography variant="h6" alignContent="center">DBP Foundation</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
      <img src={smlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
       <Typography variant="h6" alignContent="center">Fil-Am Society of Masters and Past Masters in California Masonry</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={smlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
       <Typography variant="h6" alignContent="center">Good Shepherd Followers Foundation </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={gokongweilogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw", height: "10vh", width: "5vw"}}/>
        <Typography variant="h6" alignContent="center">Gokongwei Brothers Foundation, Inc. </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={smlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
        <Typography variant="h6" alignContent="center">International Container Terminal Services Fouundation, Inc.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={lingaplogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
        <Typography variant="h6" alignContent="center">Lingap Adhikain Foundation, Inc.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={lccklogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
        <Typography variant="h6" alignContent="center">Luis Co Chi Kiat Foundation, Inc.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={smlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
        <Typography variant="h6" alignContent="center">Manila Mt. Lebanon Charity Fund</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" alignContent="center">Master Rui de Bhodovissta Foundation (MIAO)</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
      <img src={megaworldlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
        <Typography variant="h6" alignContent="center">Megaworld Foundation, Inc.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" alignContent="center">Megaworld Foundation</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={mptclogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
        <Typography variant="h6" alignContent="center">Metro Pacific Tollways Corporation</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={smlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
        <Typography variant="h6" alignContent="center">MSN Foundation, Inc.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={smlogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
        <Typography variant="h6" alignContent="center">MSN Foundation, Inc.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={picpalogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
        <Typography variant="h6" alignContent="center">Philippine Institute of Certified Public Accountants Foundation, Inc. (PICPA)</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={plmscholarslogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
        <Typography variant="h6" alignContent="center">PLM-Scholarship Foundation, Inc.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={pwclogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
        <Typography variant="h6" alignContent="center">PricewaterhouseCoopers Acceleration Center Manila</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={reallifelogo} alt="plm.edu.ph" style={{ color: 'white', padding: ".5vh .5vw"}}/>
        <Typography variant="h6" alignContent="center">Real Life Foundation, Inc.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" alignContent="center">Security Bank Foundation, Inc.</Typography>
        <img src={securitybanklogo} alt="plm.edu.ph"  style={{ color: 'white', padding: ".5vh .5vw"}}/>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" alignContent="center">Simplicio Gamboa, Sr. Foundation</Typography>
        <img src={simpliciologo} alt="plm.edu.ph"  style={{ color: 'white', padding: ".5vh .5vw"}}/>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", direction: "column", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6" alignContent="center">SM Foundation</Typography>
        <img src={smlogo} alt="plm.edu.ph"  style={{ color: 'white', padding: ".5vh .5vw"}}/>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="center">The following are the three (3) individual providers/sponsors who are mostly PLM officials, alumni, philanthropists, and friends of PLM:</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">* Ambassador and former Regent Ramon Bagatsing, Jr.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">* Mr. Marc Gorospe</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">* Mr. Russel Tena</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="center">Frequently Asked Questions (FAQs)</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="center">How are scholars selected?</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">The scholarship providers evaluate and approve applications based on the following requirements:</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">* Family annual income of Php 300,000.00 or less</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">* General Weighted Average (GWA) of not less than 2.25</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">* With no previous scholarship application nor a recipient of any scholarship grant</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">* Full-time student (from all levels) with regular load</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">* With good moral character</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="center">Who can become scholarship providers?</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">Organizations or individuals interested to provide scholarships to PLM students may contact the Office of Public Affairs (OPA) and enter into a Memorandum of Agreement subject to the Board of Regent's approval.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">Inquiries and letters of intent may be addressed to:<br/>

Dina C. Mendez<br/>                                                                      

OIC, Public Affairs<br/> 

Email: vppa@plm.edu.ph</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">Once the partnership is approved, the OPA contacts the University Committee on Scholarship and Office of Student Development and Services (OSDS) as Committee Secretariat about the scholarship requirements and slots for dissemination to the colleges and Information and Communications Technology (ICTO) so that students who need the financial aid could apply.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">The colleges receive applications from interested students. Then it submits the applications to the OSDS, which creates a shortlist using the scholarship providers' criteria. This list is submitted to the Committee on Scholarship, which makes the final recommendation and submits it to the OPA. The OPA then coordinates the recommended scholars to the scholarship providers for their concurrence.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="center">The PLM College of Law maintains a scholarship program care for the deserving and qualified students in need who are residents of Manila.</Typography>
      </Box>
      <br/>
      <br/>
      <Footer/>
    </div>
  );
}

export default Scholarship;
