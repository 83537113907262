import { AppBar, Button, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import UP1 from './img/administration/Atty. Carlo Florendo C. Castro, University Board Secretary.png';
import UP2 from './img/administration/Joseph Berlin P. Juanzon, Ph.D.png';
import UP3 from './img/administration/April May S. Bonoan.png';
import UP4 from './img/administration/Argel G. Solis.png';
import UP5 from './img/administration/Harold Perfecto R. Galang.png';
import VPASRE7 from './img/administration/Benedicto L. Avila.png';
import VPASRE1 from './img/administration/Engr. Erwin D. Marcelo.png';
import VPASRE4 from './img/administration/Margielou B. Peralta.png';
import VPASRE2 from './img/administration/Mr. Jesus Raymond R. Mijares.png';
import VPASRE5 from './img/administration/Mr. Mark John S. Bonifacio.png';
import VPASRE6 from './img/administration/Dr. Lynnette G. Cleto.png';
import VPASRE3 from './img/administration/Liezel A. Zafra.png';
import VPA1 from './img/administration/Ms. Emily E. Espero.png';
import VPA5 from './img/administration/Ms. Herminia D. Nuñez.png';
import VPA7 from './img/administration/Dr. Maria Ana B. Mariano.png';
import VPA2 from './img/administration/Mr. Reynaldo J. Villegas.png';
import VPA3 from './img/administration/Prof. Mark Christopher R. Blanco.png';
import VPA6 from './img/administration/Engr. Bryan C. Gulapa.png';
import VPA4 from './img/administration/Engr. Karen L. Leyson.png';
import VPA8 from './img/administration/Dr. Ronaldo A. Tan.png';
import VPF3 from './img/administration/Ms. Nanette E. Laurente.png';
import VPF1 from './img/administration/Ms. Andrea E. Solomon-Malunes.png';
import VPF2 from './img/administration/Ms. Maribeth P. Solquio.png';
import VPF5 from './img/administration/Ms. Dina C. Mendez.png';
import VPF4 from './img/administration/Mr. Gilbert S. Dador.png';
import EmailIcon from '@mui/icons-material/EmailRounded';

import { useRef } from 'react';

const DirectorChief = () => {
  const director = useRef(document.createElement("p"));
  const handleBoard = () => {
    window.location.href="./Administration";
  };

  const handlePresident = () => {
    window.location.href="./President";
  };

  const handleVPresident = () => {
    window.location.href="./VPresident";
  };

  const handleDean = () => {
    window.location.href="./Dean";
  };

  const handleOrgChart = () => {
    window.location.href="./OrgChart";
  };

  const handleSupportStaff = () => {
    window.location.href="./SupportStaff";
  };

  const handlePastPresident = () => {
    window.location.href="./PastPresident";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Directors and Chiefs</Typography>
      </Box>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "2vw"}}>
      <AppBar position="relative"style={{ background: 'white', height: "5vw", marginTop: "1vw"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem onClick={handleBoard}>Board of Regents</MenuItem>
        <MenuItem onClick={handlePresident}>The President</MenuItem>
        <MenuItem onClick={handleVPresident}>Management Committee</MenuItem>
        <MenuItem
          onClick={() =>
            director.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          Directors and Chiefs
        </MenuItem>
        <MenuItem onClick={handleDean}>Deans</MenuItem>
        <MenuItem onClick={handleOrgChart}>Organizational Chart</MenuItem>
        <MenuItem onClick={handleSupportStaff}>Presidential Support Staff</MenuItem>
        <MenuItem onClick={handlePastPresident}>Past University Presidents</MenuItem>        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box ref={director} sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '2vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Officials reporting to the Office of the University President</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '0vw 5vw', height: '.5vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={UP1} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={UP2} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={UP3} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={UP4} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={UP5} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= ".75vw">Atty. Carlo Florendo C. Castro</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= ".75vw">Dr. Joseph Berlin P. Juanzon</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= ".75vw">Prof. April May S. Bonoan</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= ".75vw">Mr. Argel G. Solis</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= ".75vw">Prof. Harold Perfecto R. Galang</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">cfcastro@plm.edu.ph</Typography></Button></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">jbpjuanzon@plm.edu.ph</Typography></Button></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">amsbonoan@plm.edu.ph</Typography></Button></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">argsolis@plm.edu.ph</Typography></Button></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">hprgalang@plm.edu.ph</Typography></Button></Box>   
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Chief, Office of the University Legal Counsel</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Executive Director of the Office for Graduate and Professional Studies</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">In-Charge for Sports, Presidential Committee on Arts, Culture and Sports</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">OIC, Internal Audit Office</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Vice-Chair for Arts and Culture, Presidential Committee on Arts, Culture and Sports</Typography></Box>
      </Box>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '5vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Officials reporting to the Vice President for Academic Services, Research & Extension</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '.5vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPASRE1} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPASRE2} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPASRE3} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPASRE4} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Engr. Erwin D. Marcelo</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Mr. Jesus Raymond R. Mijares</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Prof. Liezel A. Zafra</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Ms. Margielou B. Peralta</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">edmarcelo@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">jrrmijares@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">lazafra@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">mbperalta@plm.edu.ph</Typography></Button></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Admission Officer, Admission Office</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Chief, University Library</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Director, Center of University Extension Services</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Director, Office of the Guidance and Testing Services</Typography></Box>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '.5vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPASRE5} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPASRE6} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPASRE7} width="100%" alt="" /></Box>

      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Mr. Mark John S. Bonifacio</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Dr. Lynnette G. Cleto</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Dr. Benedicto L. Avila</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">mjsbonifacio@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}}variant="body2" fontSize= "0.75vw">lgcleto@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}}variant="body2" fontSize= "0.75vw">blavila@plm.edu.ph</Typography></Button></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Director, Office of the National Service Training Program</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Director, Office of Student Development & Services</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">University Registrar</Typography></Box>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '5vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Officials reporting to the Vice President for Administration</Typography>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '.5vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPA1} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPA2} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPA3} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPA4} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Ms. Emily E. Espero</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Mr. Reynaldo J. Villegas</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Prof. Mark Christopher R. Blanco</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Engr. Karen L. Leyson</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">eeespero@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">rjvillegas@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">mcrblanco@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">klleyson@plm.edu.ph</Typography></Button></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Chief, General Services Office</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Chief, Human Resource Management Office</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Chief, Information and Communications Technology Office</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Chief, Physical Facilities Management Office</Typography></Box>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '.5vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPA5} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPA6} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPA7} width="100%" alt="" /></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><img src={VPA8} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Ms. Herminia D. Nuñez</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Engr. Bryan C. Gulapa</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Dr. Maria Ana B. Mariano</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Dr. Ronaldo A. Tan</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">hdnunez@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">bcgulapa@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">mabmariano@plm.edu.ph</Typography></Button></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">ratan@plm.edu.ph</Typography></Button></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Chief, Planning Management Office</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Chief, Property and Supplies Office</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Chief, University Health Services</Typography></Box>
        <Box width="25%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">OIC - University Security Office</Typography></Box>
      </Box>
      <br/>

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '5vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Officials reporting to the Vice President for Finance</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '2vw', display: { xs: 'none', md: 'flex' } }} />
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={VPF1} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={VPF2} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={VPF3} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={VPF4} width="100%" alt="" /></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><img src={VPF5} width="100%" alt="" /></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Ms. Andrea E. Solomon-Malunes</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Ms. Maribeth P. Solquio</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Ms. Nanette E. Laurente</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Mr. Gilbert S. Dador</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Ms. Dina C. Mendez</Typography></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">aesolomon@plm.edu.ph</Typography></Button></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">mpsolquio@plm.edu.ph</Typography></Button></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">nelaurente@plm.edu.ph</Typography></Button></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">gsdador@plm.edu.ph</Typography></Button></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Button variant="text" startIcon={<EmailIcon style={{ color: 'blue', padding: "0px"}} />}><Typography style={{textTransform: 'none'}} variant="body2" fontSize= "0.75vw">dcmendez@plm.edu.ph</Typography></Button></Box>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Chief, Accounting Office</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">OIC, Budget Office</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">University Treasurer, Office of the University Treasurer</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">OIC, Procurement Office</Typography></Box>
        <Box width="20%" style={{  marginLeft: "1vw"}}><Typography variant="body2" fontSize= "0.75vw">Acting Chief, Resource Generation Office</Typography></Box>
      </Box>
      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default DirectorChief;
