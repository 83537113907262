import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const PLMAT = () => {
  const plmat = useRef(document.createElement("p"));

  const handleCMAT = () => {
    window.location.href="./CMAT";
  };

  const handleCLAT = () => {
    window.location.href="./CLAT";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">PLM Admission Test (PLMAT)</Typography>
      </Box>      
      <br/>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "10%", paddingLeft: "15px"}}>
      <AppBar position="relative"style={{ background: 'white', height: "5vw", marginTop: "1vw"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
        <MenuItem
          onClick={() =>
            plmat.current.scrollIntoView({
              behavior: "smooth",
              block: "start"       
            })
          }
        >
          PLM Admission Test (PLMAT)
        </MenuItem>
        <MenuItem onClick={handleCMAT}>College of Medicine Admission Test (CMAT)</MenuItem>
        <MenuItem onClick={handleCLAT}>College of Law Admission Test (CLAT)</MenuItem>
        </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "90%"}}>
      <Box ref={plmat} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">Pamantasan ng Lungsod ng Maynila Admission Test (PLMAT) 2025</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="left">Qualification</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* A Grade 12 student who is currently enrolled in a Senior High School (SHS) accredited by Department of Education (DepED) or ;</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* Graduate from senior high school and has not taken any college or university units/programs prior or during the application or ;</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* An Alternative Learning System (ALS) completer and Accreditation & Equivalency (A&E) passer (recommended for tertiary education) and has not yet take any college or university units/programs prior or during the application.</Typography>
      </Box>  
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="left">Requirements</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* Philippine Statistics Authority (PSA) Birth Certificate</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* For Senior High School (SHS) - Grade 11 Certificate of General Weighted Average (GWA) (GWA-Philippine’s standard and competency-based grading system) </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* For Alternative Learning System (ALS) completers – Certificate of ALS and Accreditation and Equivalency (ALS A&E) Test </Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h5" alignContent="left">Application Process</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* Visit PLM Admission Portal <a href="https://web1.plm.edu.ph/crs/admission/plMAT2025/" >(https://web1.plm.edu.ph/crs/admission/plMAT2025/)</a></Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* Fill out the Application Form (online).</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* Upload required application requirements (on-line).</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">** PSA Birth Certificate</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">** Certificate of Grade 11 GWA </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* Check email for the account credentials after submitting the application successfully. </Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* Log in to PLM admission account on a regular basis to check the status of the application.</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* Print and upload scanned-signed test permit with ID picture (2 x 2 colored with white background).</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">* On the specified examination date, bring two (2) signed test permits with an ID picture (2x2 colored with white background).</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <a href='./CollegeCASBE'> <Typography variant="h6" alignContent="left">Programs in PLM</Typography></a>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">For more information, you may contact the Admissions Office:</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0px 256px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h6" alignContent="left">Email: <a href="mailto:admission_office@plm.edu.ph">admission_office@plm.edu.ph</a></Typography>
      </Box>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default PLMAT;
