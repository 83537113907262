import { Component } from 'react'
import ReactPlayer from 'react-player'

class Video extends Component {

    render () {
        return (
          <div className='player-wrapper'>
            <ReactPlayer
              className='react-player fixed-bottom'
              url='videos/WEBSITE BANNER.MP4'
              width='100%'
              height='100%'
              loop={true}
              playing={true}
              controls={false}
              autoPlay={true}
              progressInterval={500}
              volume={1}
              muted={true} />
            </div>
        )
    }
}

export default Video;