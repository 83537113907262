import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import { useRef } from 'react';
// import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

// const Textarea = styled(BaseTextareaAutosize)(
//   ({ theme }) => `
//   box-sizing: border-box;
//   width: 320px;
//   font-family: 'IBM Plex Sans', sans-serif;
//   font-size: 0.875rem;
//   font-weight: 400;
//   line-height: 1.5;
//   padding: 8px 12px;
//   border-radius: 8px;
//   color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
//   background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
//   border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
//   box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};

//   &:hover {
//     border-color: ${blue[400]};
//   }

//   &:focus {
//     border-color: ${blue[400]};
//     box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
//   }

//   // firefox
//   &:focus-visible {
//     outline: 0;
//   }
// `,
// );

const Contact = () => {
  const contact = useRef(document.createElement("p"));

  const handleContact = () => {
    window.location.href="./Contact";
  };

  const handleContactCollege = () => {
    window.location.href="./ContactCollege";
  };

  const handleContactOffice = () => {
    window.location.href="./ContactOffice";
  };

  const handleContactExecutive = () => {
    window.location.href="./ContactExecutive";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Contact Information</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "2vw"}}>
        <AppBar position="relative"style={{ background: 'white', height: "5vw", marginTop: "1vw"}}>
          <Stack direction="row" spacing={2}>
            <Paper elevation={2} >
              <MenuList>
                <MenuItem
                  onClick={() =>
                    contact.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Contact PLM
                </MenuItem>
                <MenuItem onClick={handleContactCollege}>PLM Colleges</MenuItem>
                <MenuItem onClick={handleContactOffice}>PLM Offices</MenuItem>
                <MenuItem onClick={handleContactExecutive}>PLM Executive Offices</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Paper elevation={2} sx={{width: "25%", height: "70%", margin: '50px 200px 150px 600px'}}>
        <Box ref={contact} sx={{ flexGrow: 0, justifyContent: "center", padding: '36px 0px 8px 0px', display: { xs: 'none', md: 'flex' } }} >
          <form autoComplete="off" action="/complete" method="get">
          <br/>            <br/>
            <label htmlFor="name">Full Name&nbsp;&nbsp;&nbsp; </label>
            <input type="text" id="name" name="name"/><br />
            <br/>
            <label htmlFor="email">Email Addr &nbsp; </label>
            <input type="text" id="email" name="email"/><br />
            <br/>
            <label htmlFor="name">Contact info  </label>
            <input type="text" id="contact" name="contact"/><br />
            <br/>            <br/>
            <br/>            <br/>
            <input type="submit" value="Submit"/>
          </form>
        </Box>
        </Paper>
      </Box>
      </Box>
        <br/>
        <br/>
        <br/>
      <Footer/>
    </div>
  );
}

export default Contact;
