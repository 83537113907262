import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
// import InnerBanner from './img/aboutplm.jpg';

const CollegePT = () => {

  const pt = useRef(document.createElement("p"));

  const handleCollegeCASBE = () => {
    window.location.href="./CollegeCASBE";
  };

  const handleCollegeCBA = () => {
    window.location.href="./CollegeCBA";
  };

  const handleCollegeCA = () => {
    window.location.href="./CollegeCA";
  };

  const handleCollegeCTHM = () => {
    window.location.href="./CollegeCTHM";
  };

  const handleCollegeCE = () => {
    window.location.href="./CollegeCE";
  };

  const handleCollegeCISTM = () => {
    window.location.href="./CollegeCISTM";
  };

  const handleCollegeCED = () => {
    window.location.href="./CollegeCED";
  };

  const handleCollegeCHASS = () => {
    window.location.href="./CollegeCHASS";
  };

  const handleCollegeNursing = () => {
    window.location.href="./CollegeNursing";
  };

  const handleCollegeScience = () => {
    window.location.href="./CollegeScience";
  };

  const handleCollegeLaw = () => {
    window.location.href="./CollegeLaw";
  };

  const handleCollegeMedicine = () => {
    window.location.href="./CollegeMedicine";
  };

  const handleCollegeBS = () => {
    window.location.href="./CollegeBS";
  };

  const handleCollegeGLaw = () => {
    window.location.href="./CollegeGLaw";
  };

  const handleCollegePublicHealth = () => {
    window.location.href="./CollegePublicHealth";
  };

  const handleCollegeGovernment = () => {
    window.location.href="./CollegeGovernment";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Colleges</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "20%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper elevation={2} >
              <MenuList>
              <MenuItem onClick={handleCollegeCASBE}>Architecture</MenuItem>
              <MenuItem onClick={handleCollegeCBA}>Business Administration</MenuItem>
              <MenuItem onClick={handleCollegeCA}>Accountancy</MenuItem>
              <MenuItem onClick={handleCollegeCTHM}>Tourism & Hotel Management</MenuItem>
              <MenuItem onClick={handleCollegeCED}>Education</MenuItem>
              <MenuItem onClick={handleCollegeCE}>Engineering</MenuItem>
              <MenuItem onClick={handleCollegeCISTM}>Information System</MenuItem>
              <MenuItem onClick={handleCollegeCHASS}>Humanities, Arts and Social Science</MenuItem>
              <MenuItem onClick={handleCollegeNursing}>Nursing</MenuItem>
              <MenuItem
                  onClick={() =>
                    pt.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Physical Therapy
                </MenuItem>
                <MenuItem onClick={handleCollegeScience}>Science</MenuItem>
                <MenuItem onClick={handleCollegeLaw}>Law</MenuItem>
                <MenuItem onClick={handleCollegeMedicine}>Medicine</MenuItem>
                <MenuItem onClick={handleCollegeBS}>Business School</MenuItem>
                <MenuItem onClick={handleCollegeGLaw}>Graduate School of Law</MenuItem>
                <MenuItem onClick={handleCollegePublicHealth}>Public Health</MenuItem>
                <MenuItem onClick={handleCollegeGovernment}>Government</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "80%", paddingLeft: '50px'}}>

      <Box ref={pt} sx={{ flexGrow: 0, justifyContent: "center", padding: '8px 24px', height: '24px', display: { xs: 'none', md: 'flex' } }} />
      <Paper elevation={2} sx={{padding: '16px'}}>
          
      <Box sx={{ flexGrow: 0, justifyContent: "left", backgroundColor: "#A31920"}}>
        <Typography variant="h5" color="white">College of Physical Therapy</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
          An equally high-performing college of the University, the College of Physical Therapy lays the touchstone of powerful physical therapy education and training, enabling the college to remain consistently first to the highest in licensure examinations for the past twenty years. Contributing significantly to the promotion of wellness and the rehabilitation of physical disability, the college affirms the compassionate and unquestionable dedicated health care services of its graduates.
        </Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">History</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Through the mandate of Board Resolution No. 1636, the Pamantasan ng Lungsod ng Maynila - College of Physical Therapy (PLM CPT) opened its doors of education and training to aspiring experts in the field of health care and allied medical profession in 1993. Dr. Quintin Eala, former Dean of the PLM College of Medicine, founded the College of Physical Therapy with about 40 students. The original office was located in the former faculty room of the College of Medicine, which formerly functioned as the College's faculty and administration rooms. The office was eventually relocated to the Old Library Building. 

<br/>The PLM Physical Therapy Clinic was also established to provide PT students and interns with real-world experience while simultaneously meeting the rehabilitative needs of the PLM community and PLM adopted barangays. A separate fitness and wellness center was constructed for students and varsity athletes, with an emphasis on injury prevention and sports rehabilitation.. 

<br/>On August 2008, PLM Wellness and Fitness Center, under the supervision and management of CPT was established. The said Wellness and Fitness Center caters PLM faculty members and staff’s physical and cardiopulmonary wellness, prevention, and exercise. 

<br/>On June 2016, the College started the Master of Science in Physical Therapy program which will cater the needs of Filipino physical therapists and faculty members. It is the fourth school that offers master’s degree program in the Philippines.

<br/>Currently, CPT is headed by the Dean, and assisted administratively by the Associate Dean, MSPT Program Chair and BSPT Department Chair, and  Internship Coordinator. 
        </Typography>
      </Box>      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Vision</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        The PLM College of Physical Therapy will be the pre-eminent academic physical therapy program in the Philippines and South East Asia recognized for its leadership in education, clinical practice, extension services and research.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Mission</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        In training a new generation of physical therapists, we are committed to: 
<br/>1.        Educate future physical therapists who are knowledgeable, globally competitive, highly employable, compassionate, research-oriented, and service-oriented and who, by virtue of critical thinking, life-long learning, and ethical values, render independent judgments concerning patient/client needs 
<br/>2.        Assist community and university efforts to support the underserved populations of the Philippines in terms of extension services 
<br/>3.        Promote optimal health for our patients and society by contributing to the advancement of rehabilitation science and practice through research 
<br/>4.        Become recognize by local and international accrediting and licensing bodies or agencies as a premier college in physical therapy education.
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">College Objectives</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        1.        To provide students with various learning opportunities in order to develop the necessary knowledge, technical and communication skills in order to perform various roles expected of a physical therapist. 
<br/>2.        To conduct regular assessment and evaluation of students through valid and reliable instruments 
<br/>3.        To encourage participation of students in local and international physical therapy organization, conferences, seminars, and workshops. 
<br/>4.        To promote inter-professional collaboration 
<br/>5.        To conduct regular faculty development activities and support faculty members in participating in continuing education 
<br/>6.        To strengthen existing linkages through regular revision of MOA and develop new local and international partnership with different stakeholders   
<br/>7.        To have a regular review, appraisal and revision of curriculum based on current national and international standards, professional and societal needs 
<br/>8.        To conduct regular inspections of facilities as well as review and appraise existing learning resources as to its relevance to provide recommendations accordingly 
<br/>9.        To provide physical therapy management to underserved Filipino through affordable services 
<br/>10.        To provide community-based rehabilitation through creation of MOA with nearby barangays giving students opportunities in developing a sense of social responsibility 
<br/>11.        To encourage the production of researches that are relevant to the profession and society needs through collaborative work of faculty and students 
<br/>12.        To encourage utilization of current best evidence available in the provision of care and education 
<br/>13.        To be a consistent top performing HEI in the licensure examination for physical therapy

        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Public Offering</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        BACHELOR OF SCIENCE IN PHYSICAL THERAPY
<br/>PLM BSPT is a four-year program which provides the required educational preparation as physical therapist. Students will learn to formulate physical therapy diagnoses and provide appropriate programs of the therapeutic exercise, treatments and prevention to those with or prone to physical conditions. Student will also develop skills in learning, problem solving and decision making, which are essential for the practice of physical therapy. Clinical training will prepare students in a variety of health care settings, such as hospitals, rehabilitation and sports centers, community-based rehabilitation, and private practices. This will also prepare students in teaching in the academe and clinic on the field of physical therapy. 

<br/><br/>MASTER OF SCIENCE IN PHYSICAL THERAPY
<br/>The Master of Science in Physical Therapy is designed for physical therapists who wish to pursue advanced studies in the profession by developing advance knowledge and professional skills, integrate education, research, and clinical practice, and earn a further professional and academic qualification. 
        </Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '4px 0px', backgroundColor: "#A31920", display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5" color="white">Awards and Recognition</Typography>
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 0px', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">
        Based on the previous national PT licensure examinations since 1998, PLM-CPT has taken first place 11 times and second place 6 times. PLM has produced 77 board topnotchers, with five alumni placing first. To date, PLM-CPT has consistently remained one of the top schools out of the more than 90 existing PT universities and colleges in the Philippines.
      </Typography>
    </Box>

      <br/>
      <br/>
      <br/>
      </Paper>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default CollegePT;
