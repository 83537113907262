import { AppBar, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import Logo from './img/smalllogo.png';
import { useRef } from 'react';

const Seal = () => {
  const seal = useRef(document.createElement("p"));

  const handleUniversityProfile = () => {
    window.location.href="./UniversityProfile";
  };

  const handleGoals = () => {
    window.location.href="./Goals";
  };

  const handleHistory = () => {
    window.location.href="./History";
  };

  const handleHymn = () => {
    window.location.href="./Hymn";
  };


  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%"/> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Seal and Symbols</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "5%", paddingLeft: "2vw"}}>
      <AppBar position="relative"style={{ background: 'white', height: "5vw", marginTop: "1vw"}}>
      <Stack direction="row" spacing={2}>
      <Paper elevation={2} >
        <MenuList>
          <MenuItem onClick={handleUniversityProfile}>Vision / Mission</MenuItem>
          <MenuItem onClick={handleGoals}>Goals / Key Result Areas</MenuItem>
          <MenuItem
            onClick={() =>
              seal.current.scrollIntoView({
                behavior: "smooth",
                block: "start"       
              })
            }
          >
            Seal and Symbols
          </MenuItem>
          <MenuItem onClick={handleHistory}>PLM History</MenuItem>
          <MenuItem onClick={handleHymn}>University Hymn</MenuItem>
     </MenuList>
      </Paper>
    </Stack>
    </AppBar>
      </Box>
      <Box sx={{width: "95%"}}>
      <Box  sx={{ flexGrow: 0, justifyContent: "center", padding: '0vw 10vw 0vw 10vw', height: '200px', display: { xs: 'none', md: 'flex' } }}>
        <img src={Logo} alt="plm.edu.ph" height="200px" width="25%"/>
      </Box>
      <br/>

      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">When a new president is inducted in the university, he or she receives two precious symbols of the university, namely the Mace and the Academic Collar.</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Academic Collar</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">The Academic Collar, which bears the seal of the PLM as its gold pendant, is the first symbol bequeathed to the president during the investiture rites. By wearing it, current President has accepted to carry on his shoulder the academic leadership necessary to fulfill PLM’s mission and vision.</Typography>
      </Box>
      <br/>      

      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', height: '2vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h5">Mace</Typography>
      </Box>
      <br/>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">The Mace symbolizes the authority of the president, the source of his power to administer the university.</Typography>
      </Box>
      <Box  sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h6">It was presented first to Dr. Benito F. Reyes, the first PLM president, by then-Mayor Antonio J. Villegas on February 10, 1968. It has been recently turned over by the Chairperson to current President.</Typography>
      </Box>
      <br/>

      <br/>
      <br/>
      <br/>
      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default Seal;
