import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Typography } from '@mui/material';
import Box from '@mui/system/Box';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import page1 from './img/calendar1.jpg';
import page2 from './img/calendar2.jpg';
import EmailIcon from '@mui/icons-material/EmailRounded';

const onButtonClick = () => {
  const pdf = './img/calendar.pdf';
  const link = document.createElement("a");
  link.href = pdf;
  link.download = "universitycalendar.pdf"; // specify the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const onButtonClickH = () => {
  const pdf = './img/holiday.pdf';
  const link = document.createElement("a");
  link.href = pdf;
  link.download = "philippineholiday.pdf"; // specify the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const Calendar = () => {
  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="center">Academic Calendar (Approved by BOR)</Typography>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "center", padding: '1vw 5vw', height: '1vw', display: { xs: 'none', md: 'flex' } }}>
      <Button onClick={onButtonClick} variant="contained" startIcon={<EmailIcon style={{ color: 'white', padding: "0px"}} />}>
            <Typography  color="white" variant="body2">Download University Calendar</Typography>
      </Button>
      <Button  onClick={onButtonClickH} variant="contained" startIcon={<EmailIcon style={{ color: 'white', padding: "0px"}} />}>
            <Typography  color="white" variant="body2">Download Philippine Holiday</Typography>
      </Button>
      </Box>
      {/* <button onClick={onButtonClick}>Download University Calendar</button><br/>
      <button onClick={onButtonClickH}>Download Philippine Holiday</button> */}

      {/* <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={page1} alt="page1" width="100%"/>
      </Box>
      <br/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '0vw 10vw 0vw 10vw', display: { xs: 'none', md: 'flex' } }}>
        <img src={page2} alt="page2" width="100%"/>
      </Box> */}

<TableContainer component={Paper}>
      <Table sx={{ maxWidth: "100%" }} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center"><Typography variant="h6">SEMESTRAL</Typography></TableCell>
            <TableCell align="center"><Typography variant="h6">First Semester</Typography></TableCell>
            <TableCell align="center"><Typography variant="h6">Second Semester</Typography></TableCell>
            <TableCell align="center"><Typography variant="h6">Midyear Term</Typography></TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"><Typography variant="body2"></Typography></TableCell>
            <TableCell align="center"><Typography variant="body2">August 19, 2024 (M) - December 22, 2024 (Sun)</Typography></TableCell>
            <TableCell align="center"><Typography variant="body2">January 13, 2025 (M) - May 25, 2025 (Sun)</Typography></TableCell>
            <TableCell align="center"><Typography variant="body2">June 9, 2025 (M) - July 26, 2025 (Sun)</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">REGISTRATION PERIOD</TableCell>
              <TableCell align="center">August 12, 2024 (M) - August 18, 2024 (Sun)</TableCell>
              <TableCell align="center">January 2, 2025 (Th) - January 12, 2025 (Sun)</TableCell>
              <TableCell align="center">June 2, 2025 (M) - June 8, 2025 (Su)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">START OF CLASSES</TableCell>
              <TableCell align="center">August 19, 2024 (M)</TableCell>
              <TableCell align="center">January 13, 2025 (M)</TableCell>
              <TableCell align="center">June 9, 2025 (M)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Late Registration/Add-Drop Period</TableCell>
              <TableCell align="center">August 19, 2024 (M) - August 22, 2024 (Th)</TableCell>
              <TableCell align="center">January 13, 2025 (M) - January 16, 2025 (Th)</TableCell>
              <TableCell align="center">June 9, 2025 (M) - June 12, 2025 (Th)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Deadline for Filing of Candidacy for Graduation / Academic Honors with the College</TableCell>
              <TableCell align="center">September 18, 2024 (W)</TableCell>
              <TableCell align="center">February 12, 2025 (W)</TableCell>
              <TableCell align="center">July 2, 2025 (W)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Encoding of schedule of classes for the next school term</TableCell>
              <TableCell align="center">October 28, 2024 (M) - December 15, 2024 (Su)</TableCell>
              <TableCell align="center">March 24, 2025 (M) - May 4, 2025 (Su)</TableCell>
              <TableCell align="center">June 2, 2025 (M) - July 20, 2025 (Su)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">MIDTERM EXAMINATIONS</TableCell>
              <TableCell align="center">October 21, 2024 (M) - October 27, 2024 (Su)</TableCell>
              <TableCell align="center">March 17, 2025 (M) - March 23, 2025 (Su)</TableCell>
              <TableCell align="center">June 26, 2025 (Th) - June 28, 2025 (S)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Last Day for Graduating Students to cler their Deficiencies</TableCell>
              <TableCell align="center">November 29, 2024 (F)</TableCell>
              <TableCell align="center">May 2, 2025 (F)</TableCell>
              <TableCell align="center">July 4, 2025 (F)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">END OF CLASSES for Graduating Students</TableCell>
              <TableCell align="center">December 7, 2024 (S)</TableCell>
              <TableCell align="center">May 11, 2025 (Su)</TableCell>
              <TableCell align="center">July 20, 2025 (Su)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">END OF CLASSES for Non Graduating Students</TableCell>
              <TableCell align="center">December 15, 2024 (Su)</TableCell>
              <TableCell align="center">May 18, 2025 (Su)</TableCell>
              <TableCell align="center">July 20, 2025 (Su)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">FINAL EXAMINATION for Graduating Students</TableCell>
              <TableCell align="center">December 9, 2024 (M) - December 15, 2024 (Su)</TableCell>
              <TableCell align="center">May 12, 2025 (M) - May 18, 2025 (Su)</TableCell>
              <TableCell align="center">July 21, 2025 (M) - July 23, 2025 (W)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">FINAL EXAMINATION for Non Graduating Students</TableCell>
              <TableCell align="center">December 16, 2024 (M) - December 22, 2024 (Su)</TableCell>
              <TableCell align="center">May 19, 2025 (M) - May 25, 2025 (Su)</TableCell>
              <TableCell align="center">July 21, 2025 (M) - July 23, 2025 (W)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">ENCODING OF GRADES for Graduating Students</TableCell>
              <TableCell align="center">December 16, 2024 (M) - December 29, 2024 (Su)</TableCell>
              <TableCell align="center">May 12, 2025 (M) - May 25, 2025 (Su)</TableCell>
              <TableCell align="center">July 24, 2025 (Th) - July 29, 2025 (T)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">ENCODING OF GRADES for Non Graduating Students</TableCell>
              <TableCell align="center">December 16, 2024 (M) - December 29, 2024 (Su)</TableCell>
              <TableCell align="center">May 19, 2025 (M) - June 1, 2025 (Su)</TableCell>
              <TableCell align="center">July 24, 2025 (Th) - July 29, 2025 (T)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Christmas Break / Term Break</TableCell>
              <TableCell align="center">December 23, 2024 (M) - January 12, 2025 (Su)</TableCell>
              <TableCell align="center">May 26, 2025 (M) - June 6, 2025 (F)</TableCell>
              <TableCell align="center">July 24, 2025 (Th) - August 10, 2025 (Su)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Last Day for Submitting Report of Grades to the Registrar's Office for Graduating Students</TableCell>
              <TableCell align="center">January 3, 2025 (F)</TableCell>
              <TableCell align="center">May 30, 2025 (F)</TableCell>
              <TableCell align="center">August 1, 2025 (F)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Last Day for Submitting Report of Grades to the Registrar's Office for Non Graduating Students</TableCell>
              <TableCell align="center">January 3, 2025 (F)</TableCell>
              <TableCell align="center">June 6, 2025 (F)</TableCell>
              <TableCell align="center">August 1, 2025 (F)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Last Day for College Faculty Meeting to Approve Candidates for Graduation</TableCell>
              <TableCell align="center">January 8, 2025 (W)</TableCell>
              <TableCell align="center">June 6, 2025 (F)</TableCell>
              <TableCell align="center">August 8, 2025 (F)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Last Day for College to submit Approved Lists of Candidates for Graduation to the Registrar's Office</TableCell>
              <TableCell align="center">January 13, 2025 (M)</TableCell>
              <TableCell align="center">June 13, 2025 (F)</TableCell>
              <TableCell align="center">August 15, 2025 (F)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">University Foundation Anniversary</TableCell>
              <TableCell align="center"> - </TableCell>
              <TableCell align="center">June 19, 2025 (Th)</TableCell>
              <TableCell align="center"> - </TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Araw ng Maynila</TableCell>
              <TableCell align="center"> - </TableCell>
              <TableCell align="center">June 24, 2025 (T)</TableCell>
              <TableCell align="center"> - </TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">University Council Meeting to Approve Candidates for Graduation</TableCell>
              <TableCell align="center">January 22, 2025 (W)</TableCell>
              <TableCell align="center">July 4, 2025 (F)</TableCell>
              <TableCell align="center"> September 5, 2025 (F) </TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Board of Regents Meeting to Confirm Candidates for Graduation</TableCell>
              <TableCell align="center">January 31, 2025 (F)</TableCell>
              <TableCell align="center">July 11, 2025 (F)</TableCell>
              <TableCell align="center"> September 12, 2025 (F) </TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">57th COMMENCEMENT EXERCISES</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">September 2 to 5, 2025 (T to F)</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
      <br/>
      <TableContainer component={Paper}>
      <Table sx={{ maxWidth: "100%" }} aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center"><Typography variant="h6">TRIMESTRAL</Typography></TableCell>
            <TableCell align="center"><Typography variant="h6">First Semester</Typography></TableCell>
            <TableCell align="center"><Typography variant="h6">Second Semester</Typography></TableCell>
            <TableCell align="center"><Typography variant="h6">Third Semester</Typography></TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center"><Typography variant="body2"></Typography></TableCell>
            <TableCell align="center"><Typography variant="body2">August 27, 2024 (T) - December 15, 2024 (Sun)</Typography></TableCell>
            <TableCell align="center"><Typography variant="body2">January 6, 2025 (M) - April 27, 2025 (Sun)</Typography></TableCell>
            <TableCell align="center"><Typography variant="body2">May 5, 2025 (M) - August 17, 2025 (Sun)</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">REGISTRATION PERIOD</TableCell>
              <TableCell align="center">August 19, 2024 (M) - August 25, 2024 (Sun)</TableCell>
              <TableCell align="center">December 16, 2025 (Th) - January 5, 2025 (Sun)</TableCell>
              <TableCell align="center">April 28, 2025 (M) - May 4, 2025 (Su)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">START OF CLASSES</TableCell>
              <TableCell align="center">August 27, 2024 (M)</TableCell>
              <TableCell align="center">January 6, 2025 (M)</TableCell>
              <TableCell align="center">May 5, 2025 (M)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Late Registration/Add-Drop Period</TableCell>
              <TableCell align="center">August 27, 2024 (T) - August 30, 2024 (F)</TableCell>
              <TableCell align="center">January 6, 2025 (M) - January 9, 2025 (Th)</TableCell>
              <TableCell align="center">May 5, 2025 (M) - May 8, 2025 (Th)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Deadline for Filing of Candidacy for Graduation / Academic Honors with the College</TableCell>
              <TableCell align="center">October 2, 2024 (W)</TableCell>
              <TableCell align="center">February 12, 2025 (W)</TableCell>
              <TableCell align="center">June 4, 2025 (W)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Encoding of schedule of classes for the next school term</TableCell>
              <TableCell align="center">November 4, 2024 (M) - December 8, 2024 (Su)</TableCell>
              <TableCell align="center">March 3, 2025 (M) - April 6, 2025 (Su)</TableCell>
              <TableCell align="center">June 3, 2025 (M) - July 20, 2025 (Su)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Last Day for Graduating Students to cler their Deficiencies</TableCell>
              <TableCell align="center">November 22, 2024 (F)</TableCell>
              <TableCell align="center">April 22, 2025 (F)</TableCell>
              <TableCell align="center">July 4, 2025 (F)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">END OF CLASSES</TableCell>
              <TableCell align="center">December 7, 2024 (S)</TableCell>
              <TableCell align="center">April 20, 2025 (Su)</TableCell>
              <TableCell align="center">August 10, 2025 (Su)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">FINAL EXAMINATIONS</TableCell>
              <TableCell align="center">December 9, 2024 (M) - December 15, 2024 (Su)</TableCell>
              <TableCell align="center">April 21, 2025 (M) - April 27, 2025 (Su)</TableCell>
              <TableCell align="center">August 11, 2025 (M) - August 17, 2025 (Sun)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">ENCODING OF GRADES</TableCell>
              <TableCell align="center">December 9, 2024 (M) - December 22, 2024 (Su)</TableCell>
              <TableCell align="center">April 21, 2025 (M) - May 4, 2025 (Su)</TableCell>
              <TableCell align="center">August 11, 2025 (M) - August 20, 2025 (W)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Christmas Break / Term Break</TableCell>
              <TableCell align="center">December 16, 2024 (M) - January 5, 2025 (Su)</TableCell>
              <TableCell align="center">April 28, 2025 (M) - May 4, 2025 (F)</TableCell>
              <TableCell align="center">August 18, 2025 (M) - August 24, 2025 (Su)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Last Day for Submitting Report of Grades to the Registrar's Office</TableCell>
              <TableCell align="center">January 3, 2025 (F)</TableCell>
              <TableCell align="center">May 9, 2025 (F)</TableCell>
              <TableCell align="center">August 22, 2025 (F)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
            <TableCell component="th" scope="row" align="left">Last Day for College Faculty Meeting to Approve Candidates for Graduation</TableCell>
              <TableCell align="center">January 7, 2025 (F)</TableCell>
              <TableCell align="center">May 23, 2025 (F)</TableCell>
              <TableCell align="center">August 26, 2025 (T)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
            <TableCell component="th" scope="row" align="left">Last Day for College to submit Approved Lists of Candidates for Graduation to the Registrar's Office</TableCell>
              <TableCell align="center">January 13, 2025 (M)</TableCell>
              <TableCell align="center">May 30, 2025 (F)</TableCell>
              <TableCell align="center">August 29, 2025 (F)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">University Foundation Anniversary</TableCell>
              <TableCell align="center"> - </TableCell>
              <TableCell align="center">June 19, 2025 (Th)</TableCell>
              <TableCell align="center">June 19, 2025 (Th)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Araw ng Maynila</TableCell>
              <TableCell align="center"> - </TableCell>
              <TableCell align="center">June 24, 2025 (T)</TableCell>
              <TableCell align="center">June 24, 2025 (T)</TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">University Council Meeting to Approve Candidates for Graduation</TableCell>
              <TableCell align="center">January 22, 2025 (W)</TableCell>
              <TableCell align="center">July 4, 2025 (F)</TableCell>
              <TableCell align="center"> September 5, 2025 (F) </TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">Board of Regents Meeting to Confirm Candidates for Graduation</TableCell>
              <TableCell align="center">January 31, 2025 (F)</TableCell>
              <TableCell align="center">July 11, 2025 (F)</TableCell>
              <TableCell align="center"> September 12, 2025 (F) </TableCell>
            </TableRow>
            <TableRow sx={{ border: 0 }}>
              <TableCell component="th" scope="row" align="left">57th COMMENCEMENT EXERCISES</TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center">September 2 to 5, 2025 (T to F)</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
      <br/>
      <br/>
      <br/>
      <Footer/>
    </div>
  );
}

export default Calendar;
