import { AppBar, Card, CardActionArea, CardContent, CardMedia, MenuItem, MenuList, Paper, Stack, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { useRef } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import orgChart from './img/OVPRAEOrg.jpg';

const OfficeVPRAE = () => {

  const osds = useRef(document.createElement("p"));
  const handleOfficeAccounting = () => {
    window.location.href="./OfficeAccounting";
  };
  const handleOfficeBudget = () => {
    window.location.href="./OfficeBudget";
  };
  const handleOfficeCUES = () => {
    window.location.href="./OfficeCUES";
  };
  const handleOfficeGSO = () => {
    window.location.href="./OfficeGSO";
  };
  const handleOfficeAudit = () => {
    window.location.href="./OfficeAudit";
  };
  const handleOfficeGraduate = () => {
    window.location.href="./OfficeGraduate";
  };
  const handleOfficeOGTS = () => {
    window.location.href="./OfficeOGTS";
  };
  const handleOfficeNSTP = () => {
    window.location.href="./OfficeNSTP";
  };
  const handleOfficeOSDS = () => {
    window.location.href="./OfficeOSDS";
  };
  const handleOfficeULC = () => {
    window.location.href="./OfficeULC";
  };
  const handleOfficeOUR = () => {
    window.location.href="./OfficeOUR";
  };
  const handleOfficeUsec = () => {
    window.location.href="./OfficeUsec";
  };
  const handleOfficeVPRAE = () => {
    window.location.href="./OfficeVPRAE";
  };
  const handleOfficePFMO = () => {
    window.location.href="./OfficePFMO";
  };
  const handleOfficePMO = () => {
    window.location.href="./OfficePMO";
  };
  const handleOfficeLawCenter = () => {
    window.location.href="./OfficeLawCenter";
  };
  const handleOfficePO = () => {
    window.location.href="./OfficePO";
  };
  const handleOfficePSO = () => {
    window.location.href="./OfficePSO";
  };
  const handleOfficeRevenue = () => {
    window.location.href="./OfficeRevenue";
  };
  const handleOfficeUHS = () => {
    window.location.href="./OfficeUHS";
  };
  const handleOfficeLibrary = () => {
    window.location.href="./OfficeLibrary";
  };
  const handleOfficeURC = () => {
    window.location.href="./OfficeURC";
  };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 0, justifyContent: "center", display: { xs: 'none', md: 'flex' } }}>
        {/* <img src={InnerBanner} width="100%" alt="" /> */}
      </Box>
      <Box sx={{ flexGrow: 0, justifyContent: "flex-end",  height: '1vw', display: { xs: 'none', md: 'flex' } }} />
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '8px 60px', height: '24px', display: { xs: 'none', md: 'flex' } }}>
       <Typography variant="h4" alignContent="left">Offices</Typography>
      </Box>
      <br/>
      <Box sx={{direction: "row", display: 'flex'}}>
      <Box sx={{width: "25%", padding: "35px 0px 8px 8px", marginLeft: "25px"}}>
        <AppBar position="relative"style={{ background: 'white', marginTop: "4px", width: "100%"}}>
          <Stack spacing={2}>
            <Paper  sx={{padding: '8px'}}>
              <MenuList>
                <MenuItem onClick={handleOfficeAccounting}>Accounting Office</MenuItem>
                <MenuItem onClick={handleOfficeBudget}>Budget Office</MenuItem>
                <MenuItem onClick={handleOfficeCUES}>Center of University Extension Services</MenuItem>
                <MenuItem onClick={handleOfficeGSO}>General Services Office</MenuItem>
                <MenuItem onClick={handleOfficeAudit}>Internal Audit Office</MenuItem>
                <MenuItem onClick={handleOfficeGraduate}>Office of Graduate/Professional Studies</MenuItem>
                <MenuItem onClick={handleOfficeOGTS}>Office of Guidance & Testing Services</MenuItem>
                <MenuItem onClick={handleOfficeNSTP}>Office of NSTP</MenuItem>

                <MenuItem onClick={handleOfficeOSDS}>Office of Student Development Services</MenuItem>

                <MenuItem onClick={handleOfficeULC}>Office of the University Legal Counsel</MenuItem>
                <MenuItem onClick={handleOfficeOUR}>Office of the University Registrar</MenuItem>
                <MenuItem onClick={handleOfficeUsec}>Office of the University Secretary</MenuItem>
                <MenuItem
                  onClick={() =>
                    osds.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start"       
                    })
                  }
                >
                  Office of the VP for Research
                </MenuItem>
                <MenuItem onClick={handleOfficePFMO}>Physical Facilities Management Office</MenuItem>
                <MenuItem onClick={handleOfficePMO}>Planning And Management Office</MenuItem>
                <MenuItem onClick={handleOfficeLawCenter}>PLM Law Center</MenuItem>
                <MenuItem onClick={handleOfficePO}>Procurement Office</MenuItem>
                <MenuItem onClick={handleOfficePSO}>Property & Supplies Office</MenuItem>
                <MenuItem onClick={handleOfficeRevenue}>Revenue Generation Office</MenuItem>
                <MenuItem onClick={handleOfficeUHS}>University Health Services</MenuItem>
                <MenuItem onClick={handleOfficeLibrary}>University Library</MenuItem>
                <MenuItem onClick={handleOfficeURC}>University Research Center</MenuItem>
              </MenuList>
            </Paper>
          </Stack>
        </AppBar>
      </Box>
      <Box sx={{width: "75%", paddingLeft: '50px'}}>

      <Card >
      <CardActionArea>
        <CardMedia
          component="img"
          height="100%"
          width="100%"
          image={orgChart}
          alt="org chart"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Office of the Vice President for Research, Academic, and Extension
          </Typography>
          <Typography variant="body2" color="text.secondary">
          The OVPRAES shall assist the VPRAES in the performance of official functions: 
        <br/>a.) Research Faciltation  
        <br/>b.) Extension Programs 
        <br/>c.) Supporting Innovation 
        <br/>d.) Collaborations and Partnerships 
        <br/>e.) Capacity Building         
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

    <Card >
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Vision
          </Typography>
          <Typography variant="body2" color="text.secondary">
          The Office of the Vice President for Research, Academic and Extension Services vision is to become a prominent that is known for its exceptional research, academic programs, and outreach efforts. We aspire to garner recognition on a local and international scale for our contributions to knowledge and society.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

    <Card >
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
          Mission
          </Typography>
          <Typography variant="body2" color="text.secondary">
          The Office of the Vice President for Research, Academic, and Extension Services is committed to creating an active and diverse educational atmosphere that encourages innovative research, excellent education, and meaningful community involvement.
        <br/>Our primary focus is to promote the acquisition of information and facilitate intellectual pursuits. By fostering collaborative partnerships with local and international institutions, our goal is to make significant contributions to the intellectual, cultural, and socio-economic advancement of our community and beyond.
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>

      </Box>
      </Box>
      <Footer/>
    </div>
  );
}

export default OfficeVPRAE;
