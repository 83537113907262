import './App.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import aboutcard from './img/award60.png';
import Button from '@mui/material/Button';

const Award60 = () => {
  return (
    <>
      <hr/>
      <Box sx={{ flexGrow: 0, justifyContent: "left", padding: '1vw 1vh', height: 'auto', display: { xs: 'none', md: 'flex' } }}>
        <Typography color="#A31920" variant="h5">NOMINATIONS NOW OPEN FOR THE 2025 HARIBON AWARDS</Typography><br/>
      </Box>
    <Grid container>
      <Grid item xs={4} alignContent="right "padding="1vh 2vw 1vw 2vw">
        <a href="./img/NOMINATIONSAWARDS.pdf"><img src={aboutcard} alt="NOMINATIONS NOW OPEN FOR THE 2025 HARIBON AWARDS" height="auto" width="75%" style={{ margin: "1px"}}/></a>
      </Grid>
      <Grid item xs={8} padding="1vh 2vw 1vw 0vw">
        <Typography alignItems="left" fontSize= "1.25vw">The Pamantasan ng Lungsod ng Maynila proudly invites you to
nominate outstanding alumni who embody our Core Principles of
Karunungan (Wisdom), Kaunlaran (Progress), and Kadakilaan
(Greatness).</Typography>
        <br/>
        <Typography alignItems="left" fontSize= "1.25vw">These prestigious awards aim to honor PLM alumni who have
made significant contributions in their respective fields,
communities, and the nation. Join us in celebrating their
achievements!</Typography>
        <br/>
        <Button href="./img/NOMINATIONSAWARDS.pdf"><Typography fontSize= "1.25vw">Read More</Typography></Button>
      </Grid>
    </Grid>
    </>
  );
}

export default Award60;
